import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { DialogBox } from './../../components/DialogBox/DialogBox';
import { Topheader } from './../../components/Topheader';
import LoadingOverlay from 'react-loading-overlay';
import config from '../../constants/config';
import { DataGetApi, DataApi } from '../../helpers/PostDataApi';
import {
  addJobToPollingList,
  doesFileExist,
  formatCurrency,
  getMultipassData,
  getPaytraceCreds,
} from '../../helpers/commonFunction';
import { generateDataSource } from '../../helpers/transactionsTableSource';
import { NyTaxReports } from './../../pages/NyTaxReports';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import DataTable from 'react-data-table-component';
import DataTableExtensions from '../../components/TableExtensions/index';
import '../../components/TableExtensions/index.css';
import { setViewPreferences, fetchUserPreferences, markTransaction } from '../../actions/AdminUserAction';
import { CustomTransactionColumn } from './CustomTransactionColumn';
import classes from './Transactions.module.scss';
import { CloseableAlerts } from './../../components/Message/CloseableAlerts';
import Stack from '@mui/material/Stack';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Batches from './components/Batches';
import Alert from '@mui/material/Alert';
import { Button } from '@material-ui/core';
import SpinnerLoader from './components/SpinnerLoader';
import moment from 'moment';

const message = require('./../../constants/message');

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

class Transactions extends Component {
  toDayDate = new Date().toLocaleDateString();
  constructor(props) {
    const date = new Date();
    super(props);
    this.state = {
      sideShow: true,
      isLoading: true,
      refreshButtonLoader: false,
      isSyncDialogOpen: false,
      reload: false,
      toDate: new Date(),
      fromDate: new Date(date.setDate(date.getDate() - 30)),
      lastUpdatedDate: 'N/A',
      data1: [],
      data: [],
      columns: [],
      misMatchError: [],
      columnsPreference: null,
      invoiceLable: 'Invoice',
      value: window.location.href.includes('/Batches') ? 1 : 0,
      locationId: '',
      gateway: '',
      ACH: '',
      ACHError: '',
      showAlert: true,
    };
  }

  componentDidMount() {
    window.addEventListener('preferencesReceived', this.setColumnsPreference.bind(this));
    this.props.fetchUserPreferences();
    this.getData();
  }
  UNSAFE_componentWillMount() {
    //redirect to admin portal
    const locationDetails = this.props.LocationDetail?.currentLocation;
    if (locationDetails?.customTowingBuild) {
      this.props.history.push('/Transactions');
    }
  }
  componentWillUnmount() {
    window.removeEventListener('preferencesReceived', this.setColumnsPreference);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.UserDetail &&
      nextProps.UserDetail.userViewPreferences &&
      nextProps.UserDetail.userViewPreferences.ViewColumns
    ) {
      const preferenceReceivedEvent = new CustomEvent('preferencesReceived', {
        detail: nextProps.UserDetail?.userViewPreferences?.ViewColumns,
      });
      window.dispatchEvent(preferenceReceivedEvent);
      this.setState({ columnsPreference: nextProps?.UserDetail?.userViewPreferences?.ViewColumns });
    }

    const locationCurrent = JSON.parse(localStorage.getItem('locationPaydetails'));
    this.setState({ gateway: locationCurrent.gateway });
    this.setState({ ACH: locationCurrent.ACH });
    this.setState({
      ACHError:
        "Notice: ACH payments may be subject to returns. Even if funds are deposited, they could be returned to the customer's bank. Please monitor your transactions regularly.",
    });

    if (
      locationCurrent &&
      locationCurrent?.customFieldsEnabled &&
      locationCurrent?.customFields &&
      locationCurrent?.customFields.length > 0
    ) {
      const customFieldEnabled = locationCurrent.customFields.find((customField) => customField?.enabled);
      if (customFieldEnabled) {
        this.setState({ invoiceLable: customFieldEnabled?.value });
      }
    }

    if (
      locationCurrent &&
      locationCurrent.customTransactionColumnEnabled &&
      locationCurrent.customTransactionColumnFields.length > 0
    ) {
      const customTransactionColumn = locationCurrent.customTransactionColumnFields.find(
        (customField) => customField?.enabled
      );
      if (customTransactionColumn) {
        this.setState({ transactionColumn: customTransactionColumn?.value });
      }
    }
  }

  setColumnsPreference(event) {
    this.setState({ columnsPreference: event.detail });
  }
  handleDateChange = (date, key) => {
    if (date == 'Invalid Date' || date == null) {
      return false;
    } else {
      this.setState({ [key]: date, isLoading: true }, () => {
        this.getData();
      });
    }
  };
  toDateFilter(date) {
    this.setState({ toDate: date });
  }
  fromDateFilter(date) {
    this.setState({ fromDate: date });
  }
  handleChangeId(id) {
    this.props.history.push('/Viewtransaction/' + id, { parentPage: '/Transactions' });
  }

  tableReload(status) {
    this.setState({ reload: status });
  }

  getUser = () => JSON.parse(localStorage.getItem('user'));

  shouldAllowTransactionSync = () => {
    const user = this.getUser();
    return (
      (user.role === config.ADMIN_ROLE || user.role === config.MANAGER_ROLE) &&
      this.state.gateway?.toLowerCase() === 'paytrace'
    );
  };

  handleRefreshTransactions = async () => {
    try {
      if (this.state.refreshButtonLoader) return;

      this.setState({ refreshButtonLoader: true });

      const user = this.getUser();
      let companyId = user.role !== config.SUPER_ADMIN_ROLE && user.company._id ? user.company._id : '';
      let token = user.token;
      let locationPaydetails = JSON.parse(localStorage.getItem('locationPaydetails'));
      const gatewayDetails = getPaytraceCreds();

      const localUrl = config.BASEURL + 'getPemFile?fileName=';
      let file = doesFileExist(localUrl + gatewayDetails.paytraceKey);
      if (window.paytrace && file) {
        window.paytrace.setKeyAjax(localUrl + gatewayDetails.paytraceKey);
      } else {
        let msg = 'Paytrace file not exist. Kindly contact to super admin to re-upload the key.';
        console.error(msg);
        this.setState({ refreshButtonLoader: false });
      }

      const response = await DataApi(
        {
          companyId: companyId,
          locationId: locationPaydetails ? locationPaydetails._id : '',
          locationMid: locationPaydetails ? locationPaydetails.mId : '',
          fromDate: this.state.fromDate,
          toDate: this.state.toDate,
          jobType: 'TRANSACTION_SYNC',
          userId: user._id,
        },
        config.BASEURL + '/jobs/create',
        token,
        true,
        false,
        {},
        gatewayDetails
      );

      if (typeof response.data !== 'undefined' && response.data.success) {
        addJobToPollingList(response.data.response?._id);
        this.setState({ isSyncDialogOpen: true });
      }
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    } finally {
      this.setState({ refreshButtonLoader: false });
    }
  };

  getData = async () => {
    try {
      const user = this.getUser();
      const token = user.token;
      const url = config.BASEURL;
      const locationId = localStorage.getItem('locationArrIds');
      this.setState({ locationId: locationId });
      const orderBy = '';
      const orderDirection = '';

      const param = this.constructApiParams(user, locationId, orderBy, orderDirection);

      const [result, latestJob] = await Promise.all([
        await DataGetApi(url + 'getTransactionList', param, token),
        await DataGetApi(url + '/jobs/latest', { jobType: 'TRANSACTION_SYNC' }, token),
      ]);
      if (result.status === 200) {
        this.setLastUpdatedDate(latestJob.data.response);
        const transformedData = this.transformData(result?.data?.response);
        const modifiedData = transformedData ? transformedData : null;
        this.setState({ data1: modifiedData, isLoading: false });
        if (modifiedData) {
          this.generateTableDataSource(modifiedData);
        } else {
          this.setState({
            data: [],
            page: result?.data?.pagination?.page,
            totalCount: result?.data?.pagination?.totalCount,
          });
        }

        return {
          data: modifiedData,
          page: result?.data?.pagination?.page - 1,
          totalCount: result?.data?.pagination?.totalCount,
        };
      } else {
        const errorMsg = message.FETCH_TRANSACTION_ERROR;
        this.handleError(errorMsg);
        return Promise.reject(errorMsg);
      }
    } catch (error) {
      console.error(error);
      this.setState({ isLoading: false });
      return Promise.reject(error);
    }
  };

  setLastUpdatedDate = (latestJob) => {
    this.setState({
      lastUpdatedDate: latestJob.jobStartTime ? new Date(latestJob.jobStartTime).toLocaleDateString() : 'N/A',
    });
  };

  constructApiParams = (user, locationId, orderBy, orderDirection) => {
    return {
      locationIds: locationId,
      role: user.role,
      userId: user._id,
      companyId: user.company._id,
      defaultLocation: JSON.parse(locationId).length ? JSON.parse(locationId)[0] : null,
      per_page: '',
      page: '',
      searchAll: false,
      toDate: moment(this.state.toDate).endOf('day').utc().format(),
      fromDate: moment(this.state.fromDate).startOf('day').utc().format(),
      orderBy: orderBy,
      orderDirection: orderDirection,
      permissions: user.permissions,
    };
  };

  getCreatedBy = (item) => {
    if (item.FRONT_END === 'Self Payment Link') {
      return `${item.CUSTOMERDATA.firstName} ${item.CUSTOMERDATA.lastName}`;
    } else {
      return item.createdBy ? `${item.createdBy.firstName} ${item.createdBy.lastName}` : '';
    }
  };

  transformData = (data) => {
    if (data) {
      return (
        data.length > 0 &&
        data.map((item) => ({
          ...item,
          amount: item.AMOUNT ? formatCurrency(item.AMOUNT) : '$0.00',
          inventedBy: this.getCreatedBy(item) || item.inventedBy,
          location: item.locationId ? item.locationId.locationName : '',
          posted: item.posted ?? false,
        }))
      );
    } else {
      return null;
    }
  };

  handleError = (errorMsg) => {
    const misMatchError = [errorMsg];
    this.setState({ misMatchError, isLoading: false });
  };

  generateTableDataSource(modifiedData) {
    const result = Object?.values(modifiedData);
    let { data, columns } = this.generateDataSource(result);

    if (this.state.columnsPreference !== null) {
      this.updateColumnsPreference();
      columns = this.updateColumns(columns, result);
    }

    if (this.state.transactionColumn) {
      const transactionColumnConfig = {
        name: this.state.transactionColumn,
        selector: (row) => row[this.state.transactionColumn],
        sortable: false,
        omit: this.state.columnsPreference !== null ? this.state.columnsPreference['Posted'].omit : false,
        cell: (row, _index, _column, _id) => {
          return <CustomTransactionColumn row={row} markTransaction={this.markTransaction.bind(this)} />;
        },
      };

      columns.unshift(transactionColumnConfig);
    }

    this.setState({ data: data, columns: columns });
  }

  generateDataSource(result) {
    return generateDataSource(
      result,
      classes.Underline,
      this.handleChangeId.bind(this),
      this.state.invoiceLable,
      this.state.customTransactionColumnFields,
      this.state.transactionColumn
    );
  }

  updateColumnsPreference() {
    Object.keys(this.state.columnsPreference).forEach((item) => {
      if (this.state.columnsPreference[item] === null) {
        const columnsPreference = this.state.columnsPreference;
        columnsPreference[item] = { omit: false };
        this.setState({ ...columnsPreference });
      }
    });
  }

  updateColumns(columns, result) {
    return columns.map((col) => {
      if (!['Amount', 'Tax', 'Invoice', 'Location', 'Date', 'Notes'].includes(col.name)) {
        if (col.name == result[0]?.svcFeeName) {
          col.omit = this.state.columnsPreference['SVC_Fee']?.omit ?? false;
        } else if (col.name == this.state.invoiceLable) {
          col.omit = this.state.columnsPreference['Invoice']?.omit ?? false;
        } else if (col.name == this.state.transactionColumn) {
          col.omit = this.state.columnsPreference['Posted']?.omit ?? false;
        } else {
          col.omit = this.state.columnsPreference[col.name.replaceAll(' ', '_')]?.omit ?? false;
        }
      } else {
        col.omit = this.state.columnsPreference[col.name]?.omit ?? false;
      }

      return col;
    });
  }
  checkColumn(name, checkValue) {
    const columns = this.state.columns.map((col) => {
      if (col.name == name) {
        col['omit'] = !checkValue;
        return col;
      }
      return col;
    });
    this.setState({ columns: columns });
    //the inclusion and exclusion of columns is concluded here.
  }
  saveUserSelection() {
    const columnsPreferences = {};
    this.state.columns.forEach((col) => {
      if (col.name == this.state.invoiceLable) {
        columnsPreferences['Invoice'] = {
          omit: col.omit ?? false,
        };
        return;
      }
      if (col.name == this.state.data1[0]?.svcFeeName) {
        columnsPreferences['SVC_Fee'] = {
          omit: col.omit ?? false,
        };
      }
      if (col.name == this.state.transactionColumn) {
        columnsPreferences['Posted'] = {
          omit: col.omit ?? false,
        };
      } else {
        columnsPreferences[col.name.replaceAll(' ', '_')] = {
          omit: col.omit ?? false,
        };
      }
    });
    this.props.setViewPreferences(columnsPreferences);
  }
  markTransaction(row, markValue) {
    const index = this.state.data.findIndex((rec) => rec['Transaction ID'] == row['Transaction ID']);
    if (index !== -1) {
      const testData = [...this.state.data];
      const item = { ...testData[index], Posted: !row.Posted };
      testData[index] = item;
      this.setState({ data: [...testData] });
      this.props.markTransaction({ txnid: row['Transaction ID'], mark: markValue });
    }
  }
  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MuiTypography: {
          root: {
            width: 'auto',
          },
        },
      },
    });
  removeCloseableMessage = (index) => {
    let misMatchError = this.state.misMatchError;
    delete misMatchError[index];
    this.setState({ misMatchError });
  };
  handleClose = () => {
    this.setState({ showAlert: false });
  };

  handleChangeTab = (e, value) => {
    this.setState({ value: value });
    if (value === 1) {
      this.props.history.push('/Transactions/Batches');
    } else {
      this.props.history.push('/Transactions');
    }
  };

  render() {
    const { data, columns } = this.state;
    const tableData = {
      columns,
      data,
      checkColumn: this.checkColumn.bind(this),
      saveUserSelection: this.saveUserSelection.bind(this),
    };
    const paginationComponentOptions = {
      rowsPerPageText: 'Rows Per Page',
      rangeSeparatorText: '-',
      selectAllRowsItem: true,
      selectAllRowsItemText: 'All rows',
    };

    if (this.props.UserDetail.companyId._id === '5f4fdbe29bfe9c0e243a7598') {
      columns.push(
        {
          title: 'Base amount',
          field: 'Base amount',
          render: (rowData) =>
            rowData.invoices !== null && rowData.invoices.FIRST_PENALTY_AMOUNT > 0
              ? '$' + parseFloat(rowData.invoices.FIRST_PENALTY_AMOUNT).toFixed(2)
              : '',
        },
        {
          title: 'Penalty amount',
          field: 'Penalty amount',
          render: (rowData) =>
            rowData.invoices !== null && rowData.invoices.FIRST_PENALTY_AMOUNT > 0
              ? parseFloat(rowData.invoices.amount) - parseFloat(rowData.invoices.FIRST_PENALTY_AMOUNT) > 0
                ? `$ ${parseFloat(
                    parseFloat(rowData.invoices.amount) - parseFloat(rowData.invoices.FIRST_PENALTY_AMOUNT)
                  ).toFixed(2)}`
                : parseFloat(
                    parseFloat(rowData.invoices.amount) - parseFloat(rowData.invoices.FIRST_PENALTY_AMOUNT)
                  ).toFixed(2)
              : '',
        },
        { title: 'Amount charged', field: 'amount' }
      );
    }

    const customStyles = {
      rows: {
        style: {
          borderBottomStyle: 'initial !important',
          borderBottomWidth: '0 !important',
          borderBottomColor: 'initial !important',
          ':hover div': {
            backgroundColor: '#EEEEEE',
          },
          div: {
            fontSize: '14px',
          },
        },
      },
      headCells: {
        style: {
          borderBottomStyle: 'solid',
          borderBottomWidth: '2px',
          borderBottomColor: 'rgba(0,0,0,.12)',
          div: {
            overflow: 'initial',
            whiteSpace: 'break-spaces',
            fontSize: '14px',
          },
        },
      },
      cells: {
        style: {
          borderBottomStyle: 'solid',
          borderBottomWidth: '1px',
          borderBottomColor: 'rgba(0,0,0,.12)',
          'div.parent:hover': {
            background: 'black',
          },
        },
      },
    };

    return (
      <div className="rightPanel">
        <Topheader />
        <div className="contentPart">
          <LoadingOverlay active={this.state.isLoading} spinner text="Loading your content...">
            <Stack sx={{ width: '100%' }} spacing={2}>
              {this.state.misMatchError.map((e, i) => (
                <CloseableAlerts key={i} index={i} message={e} severity="error" closer={this.removeCloseableMessage} />
              ))}
            </Stack>
            <Container component="main" maxWidth="xl">
              {this.state?.gateway === 'multipass' && this.state?.ACH && this.state.showAlert && (
                <Alert severity="warning" onClose={this.handleClose}>
                  {this.state.ACHError}
                </Alert>
              )}
              <AppBar position="static" color="default">
                <Tabs
                  value={this.state.value}
                  onChange={this.handleChangeTab}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                >
                  <Tab label="Transactions" {...a11yProps(0)} />
                  {<Tab label="Batches" {...a11yProps(1)} />}
                </Tabs>
              </AppBar>
              <TabPanel className="custom-name-classes" value={this.state.value} index={0}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    variant="inline"
                    autoOk
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline1"
                    label={'From Date'}
                    value={this.state.fromDate}
                    maxDate={this.state.toDate}
                    onChange={(date) => {
                      this.handleDateChange(date, 'fromDate');
                    }}
                    datefilter={true}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    variant="inline"
                    autoOk
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline1"
                    label={'To Date'}
                    value={this.state.toDate}
                    minDate={this.state.fromDate}
                    maxDate={this.toDayDate}
                    onChange={(date) => {
                      this.handleDateChange(date, 'toDate');
                    }}
                    datefilter={true}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
                {this.shouldAllowTransactionSync() ? (
                  <span className={classes.updatedRow}>
                    <DialogBox
                      open={this.state.isSyncDialogOpen}
                      handleClose={() => this.setState({ isSyncDialogOpen: false })}
                      message={message.SYNC_DIALOG_MESSAGE}
                    />
                    <label className={classes.labelDate}>
                      Last Updated Date: {this.state.lastUpdatedDate || 'N/A'}
                    </label>
                    <Button
                      className={classes.buttonSearch}
                      variant="outlined"
                      color="primary"
                      disabled={this.state.refreshButtonLoader}
                      onClick={this.handleRefreshTransactions}
                    >
                      {this.state.refreshButtonLoader ? (
                        <>
                          <SpinnerLoader />
                          <p style={{ marginLeft: '10px' }}>Updating</p>
                        </>
                      ) : (
                        'Sync Transactions'
                      )}
                    </Button>
                  </span>
                ) : (
                  <></>
                )}
                <MuiThemeProvider theme={this.getMuiTheme()}>
                  <DataTableExtensions {...tableData}>
                    <DataTable
                      columns={columns}
                      data={data}
                      noHeader
                      defaultSortField="id"
                      defaultSortAsc={false}
                      pagination
                      paginationComponentOptions={paginationComponentOptions}
                      highlightOnHover
                      print={true}
                      view={true}
                      customStyles={customStyles}
                    />
                  </DataTableExtensions>
                </MuiThemeProvider>
              </TabPanel>

              <TabPanel className="custom-name-classes" value={this.state.value} index={1}>
                <Batches locationData={this.state?.locationId} handleChangeTab={this.handleChangeTab} />
              </TabPanel>
            </Container>
          </LoadingOverlay>

          {this.props.UserDetail.companyId._id === '5f4fdbe29bfe9c0e243a7598' ? <NyTaxReports /> : ''}
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    info: state.PaymentReducer,
    UserDetail: state.AdminUserReducer,
    LocationDetail: state.LocationReducer,
  };
}
Transactions.propTypes = {
  UserDetail: PropTypes.any,
  LocationDetail: PropTypes.object,
  history: PropTypes.any,
  fetchUserPreferences: PropTypes.func,
  setViewPreferences: PropTypes.func,
  markTransaction: PropTypes.func,
};

export default connect(mapStateToProps, { setViewPreferences, fetchUserPreferences, markTransaction })(
  withTranslation()(Transactions)
);
