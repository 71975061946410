import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Table } from '../../components/Table';
import { Breadcrumb } from '../../components/Breadcrumb';
import Chip from '@material-ui/core/Chip';
import { Topheader } from './../../components/Topheader';
import { CompanyDetailById, CompanyAllDetailById, CompanyLocationDataById } from '../../actions/CompanyAction';
import { adminUserDelete, adminUserStatus } from '../../actions/AdminUserAction';
import { locationDelete, locationStatus } from '../../actions/LocationAction';
import LoadingOverlay from 'react-loading-overlay';
import EditUserCompanyDetails from './../CompanyDetails/EditUserCompanyDetails';
import AddUserCompanyDetails from './../CompanyDetails/AddUserCompanyDetails';
import EditLocationsCompanyDetails from './../CompanyDetails/EditLocationsCompanyDetails';
import AddLocationsCompanyDetails from './../CompanyDetails/AddLocationsCompanyDetails';
import EditIcon from '@material-ui/icons/Edit';
import Avatar from '@material-ui/core/Avatar';
import config from '../../constants/config';
import { DataGetApi } from '../../helpers/PostDataApi';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    '@media (max-width:768px)': {
      display: 'block',
      alignItems: 'center',
    },
  },

  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  mT1: {
    marginTop: `-1.5rem`,
    '@media (max-width:768px)': {
      marginTop: `0`,
    },
  },
  tab4: {
    marginTop: `-1.5rem`,
    marginRight: `-20rem`,
  },
  tab3: {
    marginTop: `-1.5rem`,
    marginRight: `-20rem`,
  },

  tabBtn: {
    '@media (max-width:768px)': {
      maxWidth: 'inherit',
    },
  },
  tpTableFullW: {
    width: '100%',
  },
}));

const getLocationNames = (locations) =>
  locations.reduce((location, curr, i) => location + (i > 0 ? ', ' + curr.locationName : curr.locationName), '');
class CompanyDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      EditUser: false,
      AddUser: false,
      EditPanel: '',
      EditLocation: false,
      AddLocation: false,
      reload: false,
      usersData: [],
      locationsData: [],
      tab: 0,
      dbaName: '',
      email: '',
      contactName: '',
      website: '',
      mcc: '',
      address1: '',
      address2: '',
      state: '',
      city: '',
      tabSelected: 'Users',
      title: 'Company',
      sideShow: true,
      isLoading: true,
      user: JSON.parse(localStorage.getItem('user')),
    };
  }

  companyUserDeleteStatus(e, data) {
    this.setState({ isLoading: true });
    this.props
      .adminUserDelete(data)
      .then(() => {
        this.getTableData({ orderBy: undefined, orderDirection: '' });
        this.setState({ isLoading: false });
        window.location.reload(false);
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.error('Error while delete user: ', error);
      });
  }
  companyLocationDeleteStatus(e, data) {
    this.props.locationDelete(data).then(() => {
      this.setState({ isLoading: true });
      this.getLocationTableData({ orderBy: undefined, orderDirection: '' });
    });
  }
  locationStatusToggle(e, data) {
    this.props.locationStatus(data).then(() => {
      this.setState({ isLoading: true });
      this.getLocationTableData({ orderBy: undefined, orderDirection: '' });
    });
  }
  userStatusToggle(e, data) {
    this.setState({ isLoading: true });
    this.getCompanyData();
    this.props
      .adminUserStatus(data)
      .then(() => {
        this.setState({ isLoading: false });
        window.location.reload(false);
        this.getTableData({ orderBy: undefined, orderDirection: '' });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.error('Error while changing user status', error);
      });
  }

  getCompanyData() {
    const {
      match: { params },
    } = this.props;
    this.setState({ isLoading: true });
    this.props.CompanyDetailById(params.id).then((res) => {
      this.setState({ ...res.payload.companyData.data.response, isLoading: false });
    });
  }

  componentDidMount() {
    this.getCompanyData();
    this.getTableData({ orderBy: undefined, orderDirection: '' });
    this.getLocationTableData({ orderBy: undefined, orderDirection: '' });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.location.search !== this.props.location.search ||
      prevState.AddUser !== this.state.AddUser ||
      prevState.AddLocation !== this.state.AddLocation
    ) {
      this.getCompanyData();
      this.getTableData({ orderBy: undefined, orderDirection: '' });
      this.getLocationTableData({ orderBy: undefined, orderDirection: '' });
    }
  }
  tableReload(status) {
    this.setState({ reload: status });
  }

  getTableData(query) {
    return new Promise((resolve, reject) => {
      let user = JSON.parse(localStorage.getItem('user'));
      let token = user.token;
      let url = config.BASEURL;
      const classProps = this.props !== undefined ? this.props : query.props;
      const {
        match: { params },
      } = classProps;
      let orderBy = query.orderBy !== undefined ? query.orderBy.field : '';
      let orderDirection = query.orderDirection !== '' ? query.orderDirection : '';
      let param = {
        orderBy,
        orderDirection,
        role: user.role,
        userId: user._id,
        companyId: user.company ? user.company._id : params.id,
        per_page: query.pageSize,
        page: +(query.page + 1),
        searchAll: typeof query.search == 'undefined' || query.search == '' ? false : true,
      };
      DataGetApi(url + 'CompanyAllDetailById', param, token)
        .then((result) => {
          resolve({
            data:
              typeof query.search === 'undefined' || query.search === ''
                ? result.data.response.map((item) => ({
                    ...item,
                    location: getLocationNames(item.location),

                    company: item.company ? item.company.dbaName : '',
                    role: item.role === 2 ? 'Admin' : item.role === 3 ? 'Manager' : 'Regular User',
                  }))
                : result.data.response
                    .filter((row) => {
                      return (
                        row.userName.toLowerCase().indexOf(String(query.search)) !== -1 ||
                        String(row.phone).toLowerCase().indexOf(String(query.search)) !== -1
                      );
                    })
                    .map((item) => ({
                      ...item,
                      location: getLocationNames(item.location),

                      company: item.company ? item.company.dbaName : '',
                      role: item.role === 2 ? 'Admin' : item.role === 3 ? 'Manager' : 'Regular User',
                    })),
            page: result.data.pagination.page - 1,
            totalCount: result.data.pagination.totalCount,
          });
        })
        .catch((error) => {
          console.error('fetching clients error : ', error);
          // toast.error('There was an error trying to fetch clients');
          reject(error);
        });
    });
  }
  getLocationTableData(query) {
    return new Promise((resolve, reject) => {
      let user = JSON.parse(localStorage.getItem('user'));
      let token = user.token;
      const classProps = this.props !== undefined ? this.props : query.props;
      const {
        match: { params },
      } = classProps;
      let url = config.BASEURL;
      let orderBy = query.orderBy !== undefined ? query.orderBy.field : '';
      let orderDirection = query.orderDirection !== '' ? query.orderDirection : '';
      let param = {
        orderBy,
        orderDirection,
        role: user.role,
        userId: user._id,
        companyId: user.company ? user.company._id : params.id,
        per_page: query.pageSize,
        page: +(query.page + 1),
        searchAll: typeof query.search == 'undefined' || query.search == '' ? false : true,
      };
      DataGetApi(url + 'CompanyLocationDataById', param, token)
        .then((result) => {
          resolve({
            data:
              typeof query.search === 'undefined' || query.search === ''
                ? result.data.response
                : result.data.response.filter((row) =>
                    row.locationName.toLowerCase().includes(String(query.search).toLowerCase())
                  ),
            page: result.data.pagination.page - 1,
            totalCount: result.data.pagination.totalCount,
          });
        })
        .catch((error) => {
          console.error('fetching clients error : ', error);
          // toast.error('There was an error trying to fetch clients');
          reject(error);
        });
    });
  }

  renderSwitch(param) {
    switch (param) {
      case 0:
        return 'Users';
      case 1:
        return 'Locations';
      case 3:
        return 'Users';
      case 4:
        return 'Users';
      case 2:
        return 'Locations';
      case 5:
        return 'Locations';
      case 6:
        return 'Locations';
      default:
        return '';
    }
  }
  handleChangeTab = (e, val, Id = null) => {
    if (this.props.location.search) {
      // delete this.props.location.search;
      this.props.history.push(this.props.location.pathname);
    }
    let tabSelected = this.renderSwitch(val);
    switch (val) {
      case 3:
        this.setState({ AddUser: false, EditUser: true, tab: 0, tabSelected: tabSelected });

        this.props.history.push(this.props.location.pathname + '?users=' + Id);
        break;
      case 4:
        this.setState({ AddUser: true, EditUser: false, tab: 0, tabSelected: tabSelected });
        this.props.history.push(this.props.location.pathname);
        break;
      case 5:
        this.setState({ AddLocation: false, EditLocation: true, tab: 1, tabSelected: tabSelected });
        this.props.history.push(this.props.location.pathname + '?locations=' + Id);
        break;
      case 6:
        this.setState({ AddLocation: true, EditLocation: false, tab: 1, tabSelected: tabSelected });
        this.props.history.push(this.props.location.pathname);
        break;
      default:
        this.getCompanyData();
        this.setState({
          tab: val,
          AddLocation: false,
          EditLocation: false,
          AddUser: false,
          EditUser: false,
          tabSelected: tabSelected,
        });
        break;
    }
  };
  handleOnclickEdit(_e) {
    const {
      match: { params },
    } = this.props;
    this.props.history.push('/EditCompany/' + params.id);
  }

  handlePaxLocationId(data) {
    if (data.paxLocationId === null || data.paxLocationId === '') {
      return 'N/A';
    }

    return data.paxLocationId;
  }

  render() {
    const LocationColumns = [
      { title: 'Location Name', field: 'locationName' },
      { title: 'Contact Name', field: 'contactName' },
      { title: 'City', field: 'city' },
      { title: 'Address', field: 'address1' },
      { title: 'Pax Location', field: 'paxLocationId', render: (row) => this.handlePaxLocationId(row) },
      { title: 'Creation Date', field: 'timeZone', render: (date) => new Date(date.timeZone).toLocaleString() },
    ];

    const UsersColumns = [
      { title: 'UserName', field: 'userName' },
      { title: 'Role', field: 'role' },
      { title: 'Phone', field: 'phone' },
      { title: 'Company Name', field: 'company' },
      { title: 'Locations', field: 'location' },
      // { title: 'Active Status', field: 'status', lookup: { true: 'Active', false: 'In Active' } }
    ];

    const { classes } = this.props;
    const UserEditTab = 3;
    const UserAddTab = 4;
    const LocationEditTab = 5;
    const LocationAddTab = 6;
    return (
      <div className="rightPanel">
        <Topheader />
        <Breadcrumb
          root={this.state.title}
          tab={this.state.tab}
          tabSelected={this.state.tabSelected}
          handleChangeTab={this.handleChangeTab}
          tabPanel={this.props.location.search.slice(1)}
        />
        <div style={{ padding: '1rem' }}>
          <Chip
            avatar={
              <Avatar>
                {' '}
                <EditIcon />{' '}
              </Avatar>
            }
            label={this.state.dbaName}
            className={classes.chip}
            onClick={(e) => this.handleOnclickEdit(e)}
          />
        </div>
        <div className={classes.root}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={this.state.tab}
            onChange={(e, val) => this.handleChangeTab(e, val)}
            aria-label="Vertical tabs example"
            className={classes.tabs}
          >
            {/* <Tab className={classes.tabBtn} label="Transactions" {...a11yProps(0)} /> */}
            <Tab className={classes.tabBtn} label="Users" {...a11yProps(1)} />
            <Tab className={classes.tabBtn} label="Locations" {...a11yProps(2)} />
          </Tabs>
          <LoadingOverlay
            className={classes.tpTableFullW}
            active={this.state.isLoading}
            spinner
            text="Loading your content..."
          >
            <div className="tp-tabcustom">
              <TabPanel value={this.state.tab} index={0} className={classes.mT1}>
                {this.state.AddUser ? (
                  <AddUserCompanyDetails
                    dbaName={this.state.dbaName}
                    props={this.props}
                    handleChangeTab={this.handleChangeTab}
                  />
                ) : this.state.EditUser ? (
                  <EditUserCompanyDetails props={this.props} handleChangeTab={this.handleChangeTab} />
                ) : (
                  <Table
                    tableReload={(e) => this.tableReload(e)}
                    companyDeleteAction={(e, data) => this.companyUserDeleteStatus(e, data)}
                    EditTab={UserEditTab}
                    AddTab={UserAddTab}
                    companyDetailsAction={(e, data, Id) => this.handleChangeTab(e, data, Id)}
                    companyActiveAction={(e, data) => this.userStatusToggle(e, data)}
                    options={{
                      exportButton: true,
                    }}
                    columns={UsersColumns}
                    data={(query) => this.getTableData(query)}
                    getExportData={this.getTableData}
                    permissions={this.props.info.permissions}
                    title={this.state.user.role === 1 || this.state.user.role === 0 ? 'Admin' : 'User'}
                    search={true}
                    exports={true}
                    paging={true}
                    companyEditDetails={{ ...a11yProps(1) }}
                  />
                )}
              </TabPanel>
              <TabPanel value={this.state.tab} index={1} className={classes.mT1}>
                {this.state.AddLocation ? (
                  <AddLocationsCompanyDetails props={this.props} handleChangeTab={this.handleChangeTab} />
                ) : this.state.EditLocation ? (
                  <EditLocationsCompanyDetails props={this.props} handleChangeTab={this.handleChangeTab} />
                ) : (
                  <Table
                    tableReload={(e) => this.tableReload(e)}
                    companyDeleteAction={(e, data) => this.companyLocationDeleteStatus(e, data)}
                    companyDetailsAction={(e, data, Id) => this.handleChangeTab(e, data, Id)}
                    EditTab={LocationEditTab}
                    AddTab={LocationAddTab}
                    companyActiveAction={(e, data) => this.locationStatusToggle(e, data)}
                    options={{
                      exportButton: true,
                    }}
                    columns={LocationColumns}
                    data={(query) => this.getLocationTableData(query)}
                    getExportData={this.getLocationTableData}
                    permissions={this.props.info.permissions}
                    title="Locations"
                    search={true}
                    exports={true}
                    paging={true}
                    companyEditDetails={{ ...a11yProps(2) }}
                  />
                )}
              </TabPanel>
            </div>
          </LoadingOverlay>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    info: state.AdminUserReducer,
  };
}
const ApplyingStylesOnClasses = (props) => {
  const classes = useStyles();
  return <CompanyDetails classes={classes} {...props} />;
};

CompanyDetails.propTypes = {
  adminUserDelete: PropTypes.func,
  match: PropTypes.object,
  location: PropTypes.object,
  classes: PropTypes.object,
  info: PropTypes.object,
  history: PropTypes.object,
  locationDelete: PropTypes.func,
  locationStatus: PropTypes.func,
  adminUserStatus: PropTypes.func,
  CompanyDetailById: PropTypes.func,
};

export default connect(mapStateToProps, {
  CompanyDetailById,
  locationDelete,
  adminUserStatus,
  adminUserDelete,
  locationStatus,
  CompanyAllDetailById,
  CompanyLocationDataById,
})(withTranslation()(ApplyingStylesOnClasses));
