import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classes from './User.module.scss';
import Container from '@material-ui/core/Container';
import { Textfield } from './../../components/Textfield';
import { Buttons } from './../../components/Buttons';
import { UserDetailByLink } from '../../actions/AdminUserAction';
import LoadingOverlay from 'react-loading-overlay';
import { config, message } from '../../constants';
import queryString from 'query-string';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import { DataApi } from './../../helpers/PostDataApi';
import Alert from '@mui/material/Alert';
import validator from '../../helpers/checkValidations';
import { checkPasswordStrength } from '../../helpers/commonFunction';
class userActivation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      misMatchError: [],
      userName: '',
      password: '',
      confirmPassword: '',
      errorMsg: null,
      successMessages: false,
      successDisplay: false,
      sideShow: true,
      isLoading: true,
      isError: false,
      btnDisabled: true,
      passwordStrength: false,
      strengthStatus: undefined,
      passwordStrengthSeverity: undefined,
      company: { image: '' },
      error: {
        passwordError: false,
        userNameError: false,
        confirmPasswordError: false,
      },
    };
  }
  componentDidMount() {
    let params = queryString.parse(this.props.location.search);

    if (params) {
      this.props
        .UserDetailByLink(params)
        .then((res) => {
          this.setState({
            company: { ...res.payload.userData.data.response.company },
            userName: res.payload.userData.data.response.userName,
            createdAt: res.payload.userData.data.response.createdAt,
            isLoading: false,
            image: res.payload.userData.data.response.image,
          });
        })
        .catch((_err) => {
          this.setState({ errorMsg: this.props.info.userInfo.data.message, isError: true, isLoading: false });
        });
    }
  }

  handleChange(event, t) {
    let error = {};
    let { id, value } = event.target;
    let { password, confirmPassword } = this.state;
    this.setState({ [id]: value, error });
    let misMatchError = [];
    let successMessages = false;

    if (id === 'password') {
      const validateLength = validator(id, value);
      if (validateLength) {
        error[id + 'Error'] = true;
        this.setState({ error, passwordStrengthSeverity: 'error', strengthStatus: 'Limit exceeded 25 characters' });
        return;
      } else {
        error[id + 'Error'] = false;
        this.setState({ error, passwordStrengthSeverity: undefined });
      }
      const { passwordStrength, strengthStatus, passwordStrengthSeverity } = checkPasswordStrength(value);
      this.setState({
        passwordStrength: passwordStrength,
        strengthStatus: strengthStatus,
        passwordStrengthSeverity: passwordStrengthSeverity,
      });
    }
    if (!this.state.passwordStrength) {
      this.setState({ misMatchError, successMessages, btnDisabled: true });
    } else if (
      (id === 'confirmPassword' && password !== event.target.value) ||
      (id === 'password' && confirmPassword !== value)
    ) {
      misMatchError[0] = t('MisMatchPassword');
      this.setState({ misMatchError, successMessages, btnDisabled: true });
    } else {
      this.setState({ misMatchError, successMessages, btnDisabled: false });
    }
  }

  handleSubmit(_e, _t) {
    let misMatchError = [];
    let successMessages = false;
    let state = Object.assign({}, this.state);
    let error = {};
    let errorFlag = false;
    Object.keys(state).map((key, _value) =>
      state[key] === '' ? ((error[key + 'Error'] = true), (errorFlag = true)) : ''
    );

    if (errorFlag) {
      this.setState({ misMatchError, error, successMessages });
      return false;
    } else {
      DataApi(state, config.BASEURL + 'adminUserUpdatePassword')
        .then((response) => {
          let { status, data } = response;
          if (status === 200) {
            this.setState({ successMessages: true, successDisplay: data.message });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  redirectLogin(_e) {
    window.location.href = '/login';
  }

  render() {
    const { t } = this.props;
    const { imageStyle } = styles;
    const { createdAt } = this.state;
    let lastUpdate = new Date(createdAt);
    let currentDateTime = new Date();
    let diffTime = currentDateTime - lastUpdate;
    let TimeOutdiff = Math.round(diffTime / 1000 / 60);
    return (
      <div className={classes.userLink}>
        <LoadingOverlay active={this.state.isLoading} spinner text="please wait...">
          <div className={classes.paper}>
            {this.state.isError ? (
              <div className={classes.error}>{this.state.errorMsg}</div>
            ) : (
              <img
                src={
                  this.state.image
                    ? this.props.PAYTRACE_AWS_PATH + this.state.image
                    : this.state.company.image
                    ? this.props.PAYTRACE_AWS_PATH + this.state.company.image
                    : `${this.props.PAYTRACE_AWS_PATH}logo-new.png`
                }
                style={imageStyle}
                height="100rem"
                alt="logo"
              />
            )}
            {TimeOutdiff > config.ACTIVATION_LINK ? (
              <p className={classes.error}>
                This link has expired. Please contact Titanium Payments support staff to receive a new one.{' '}
                <a href={config.APPURL}>back</a>
              </p>
            ) : (
              <Container component="main" maxWidth="sm">
                {this.state.isError ? (
                  ''
                ) : (
                  <form className={classes.form} noValidate>
                    <Textfield
                      required={true}
                      error={this.state.error.userNameError}
                      id="userName"
                      type="userName"
                      labels={t('Username')}
                      value={this.state.userName}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                      focus={false}
                      disabled={true}
                    />
                    <div>
                      <Textfield
                        required={true}
                        error={this.state.error.passwordError}
                        id="password"
                        type="password"
                        labels={t('Password')}
                        value={this.state.password}
                        width={true}
                        onChange={(e) => this.handleChange(e, t)}
                        focus={false}
                      />
                      {this.state.passwordStrengthSeverity !== undefined && (
                        <>
                          <div>
                            <Alert severity={this.state.passwordStrengthSeverity}>{this.state.strengthStatus}</Alert>
                          </div>
                        </>
                      )}
                    </div>
                    <Textfield
                      required={true}
                      error={this.state.error.confirmPasswordError}
                      id="confirmPassword"
                      type="password"
                      labels={t('ConfirmPassword')}
                      value={this.state.confirmPassword}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                      focus={false}
                    />
                    {this.state.successMessages ? (
                      <Buttons
                        id="login"
                        type="button"
                        width={true}
                        variant="contained"
                        color="secondary"
                        className={classes.submit}
                        onClick={(e) => this.redirectLogin(e)}
                        text={t('ClickHereLogin')}
                        disabled={this.state.btnDisabled}
                      />
                    ) : (
                      <Buttons
                        id="save"
                        type="button"
                        width={true}
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={(e) => this.handleSubmit(e, t)}
                        text={t('Save')}
                        disabled={this.state.btnDisabled}
                      />
                    )}
                    <div className="passwordPolicy">
                      <h4>{message.PASSWORD_POLICY_MESSAGE}</h4>
                    </div>
                  </form>
                )}
              </Container>
            )}
            {this.state.misMatchError.length > 0 ? <ErrorMessage errors={[this.state.misMatchError]} /> : ''}
            {this.state.successMessages ? <SuccessMessage successes={[this.state.successDisplay]} /> : ''}
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    info: state.AdminUserReducer,
    PAYTRACE_AWS_PATH: state.EnvironmentVariablesReducer.data.PAYTRACE_AWS_PATH,
  };
}
userActivation.propTypes = {
  t: PropTypes.func,
  info: PropTypes.object,
  location: PropTypes.object,
  PAYTRACE_AWS_PATH: PropTypes.string,
  UserDetailByLink: PropTypes.func,
};

const styles = {
  imageStyle: { maxWidth: '95%' },
};
export default connect(mapStateToProps, { UserDetailByLink })(withTranslation()(userActivation));
