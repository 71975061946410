import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

function Buttons(props) {
  function handleSubmit(event) {
    if (props.onClick) {
      props.onClick(event, event.target.value);
    }
  }

  return (
    <>
      <Button
        id={props.id}
        type={props.type}
        fullWidth={props.width}
        variant={props.variant}
        color={props.color}
        className={props.className}
        disabled={props.disabled}
        onClick={handleSubmit}
        startIcon={props.startIcon}
        endIcon={props.endIcon}
        {...props}
      >
        {props.text}
      </Button>
    </>
  );
}

Buttons.propTypes = {
  id: PropTypes.string,
  fullWidth: PropTypes.bool,
  type: PropTypes.string,
  width: PropTypes.bool,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
  color: PropTypes.string,
  startIcon: PropTypes.object,
  endIcon: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string,
};

Buttons.defaultProps = {
  className: '',
  variant: 'primary',
};

export default Buttons;
