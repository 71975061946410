import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import classes from './Payment.module.scss';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { getInvoiceNumber, PaymentAuthPage } from '../../actions/PaymentAction';
import { PaymentInfo, CustomTimer } from '../../components/Payment';
import TenMinsAfterExpiry from '../../components/OOPS/TenMinsAfterExpiry';
import QuoteExpired from '../../components/OOPS/QuoteExpired';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import config from './../../constants/config';
import LoadingOverlay from 'react-loading-overlay';
import { DataGetApi } from '../../helpers/PostDataApi';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';

class PaymentAuth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardPanelShow: true,
      americanExpress: false,
      isLoading: false,
      isOpen: false,
      amount: '',
      payerName: '',
      total: 0,
      achDiscount: 0,
      svcFee: 0,
      tax: 0,
      taxType: '%',
      svcType: '%',
      invoice: '',
      notes: '',
      createdBy: '',
      companyId: '',
      zip: '',
      checkboxText: '',
      check: false,
      discountType: 1,
      discount: 0,
      isSubmit: false,
      isClose: false,
      dueDate: new Date(),
      invoiceDate: new Date(),
      misMatchError: [],
      CoustmerData: [],
      CardData: [],
      InvoicePendingData: [],
      successMessages: false,
      error: {
        amountError: false,
        payerNameError: false,
      },
    };
    this.paymentRef = React.createRef();
    this.cardRef = React.createRef();
    this.msgRef = React.createRef();
  }

  componentDidMount() {
    this.getInvoiceData();
    this.getContents();
  }

  redirectToPayment(e) {
    e.preventDefault();
    this.props.history.push('/Payment/' + this.state.hash);
  }

  getInvoiceData() {
    const {
      match: { params },
    } = this.props;
    this.setState({ isLoading: true });
    this.props.PaymentAuthPage(params).then((res) => {
      if (res?.payload?.paymentData?.data?.success) {
        this.setState({ ...res.payload.paymentData.data.response, isLoading: false });
      }
      if (res?.payload?.paymentData?.data?.statusCode === 401) {
        this.setState({ invalidMsg: res.payload.paymentData.data.message, isLoading: false });
      } else {
        this.setState({ invalidMsg: 'Request to server was unsuccessful. Please try again later.', isLoading: false });
      }
    });
  }
  getContents() {
    DataGetApi(config.BASEURL + 'checkboxText').then((res) => {
      this.setState({ checkboxText: res.data.text });
    });
  }

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  render() {
    // const { t } = this.props;
    const { check, checkboxText } = this.state;
    // let lastUpdate = new Date(linkSendDate);
    // let currentDateTime = new Date();
    // let diffTime = currentDateTime - lastUpdate;
    // let TimeOutdiff = Math.round((diffTime / 1000) / 60);

    return (
      <Container component="main" maxWidth={false} className="terminal-container">
        {this.state.misMatchError.map((e, id) => (
          <ErrorMessage errors={[e]} key={id} />
        ))}
        {this.state.successMessages ? <SuccessMessage successes={[this.props.info.invoiceInfo.data.message]} /> : ''}

        <div className={this.state.expiresIn ? '' : 'payment-auth-container'}>
          <LoadingOverlay
            className={classes.tpTableFullW}
            active={this.state.isLoading}
            spinner
            text="Fetching your response ..."
          >
            <Grid container spacing={3}>
              <Grid container direction="row" justify="center">
                <Grid item>
                  {' '}
                  <div className="top-logo-panel">
                    {' '}
                    <img
                      alt="logo"
                      src={
                        this.state.locationId &&
                        this.state.locationId.image !== null &&
                        this.state.locationId.image !== 'null'
                          ? config.PAYTRACE_AWS_PATH + this.state.locationId.image
                          : config.APPURL + '/assets/images/logo.png'
                      }
                    />
                    <h3>{this.state.companyId ? this.state.companyId.dbaName : ''}</h3>
                  </div>
                </Grid>
              </Grid>
              <Grid item sm={9} style={styles.InvoiceAlign}>
                {this.state.Error_Data ? (
                  ''
                ) : (
                  <span style={styles.headingStyle}> Invoice Number: {this.state.invoice} </span>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {this.state.expiresIn ? (
                <>
                  <div style={styles.timerContainerStyle} className="topStyle">
                    <span style={{ paddingRight: '0.25rem' }}> Your truck is reserved for </span>
                    <CustomTimer expiresIn={this.state.expiresIn} />
                  </div>
                  <PaymentInfo style={styles.containerStyle} data={this.state} />
                </>
              ) : (
                ''
              )}

              {this.state.timeRemain > 0 ? (
                <TenMinsAfterExpiry time={this.state.timeRemain} data={this.state} />
              ) : this.state.timeRemain <= 0 ? (
                <QuoteExpired data={this.state} />
              ) : this.state ? (
                <p style={{ backgroundColor: '#FFF' }}>{this.state.Error_Data}</p>
              ) : (
                ''
              )}
              <center>
                {checkboxText && this.state.expiresIn ? (
                  <div className="checkboxContainerStyle">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={check}
                          value="check"
                          onChange={this.handleChange('check')}
                          icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 30 }} />}
                          checkedIcon={<CheckBoxIcon style={{ fontSize: 30 }} />}
                        />
                      }
                      label={
                        <span style={{ fontSize: '0.95rem', textAlign: 'justify' }}>
                          {(this.state.isPaymentAuthTermConditionEnabled && this.state.paymentAuthTermCondition) ||
                            checkboxText}
                        </span>
                      }
                      labelPlacement="top"
                      className="checkboxTextStyle"
                    />
                    <Grid xs={12} alignItems={'center'}>
                      <Button
                        disabled={!check || !checkboxText}
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={(e) => this.redirectToPayment(e)}
                      >
                        {' '}
                        AUTHORIZE{' '}
                      </Button>
                    </Grid>
                  </div>
                ) : (
                  ''
                )}
              </center>
            </Grid>
          </LoadingOverlay>
        </div>
      </Container>
    );
  }
}
PaymentAuth.propTypes = {
  history: PropTypes.any,
  match: PropTypes.any,
  PaymentAuthPage: PropTypes.func,
  info: PropTypes.any,
};
const styles = {
  imageStyle: { maxWidth: '95%', marginTop: '0.7rem' },
  containerStyle: { margin: '0 auto', fontSize: '1.10rem', color: '#000', padding: '0.5rem', paddingTop: 0 },
  headingStyle: { color: '#000', textAlign: 'left', fontSize: '1.22rem', fontWeight: 'bold' },
  paperStyle: { minWidth: '80%', padding: '1rem' },
  timerContainerStyle: {
    backgroundColor: '#F00',
    color: '#FBFA4E',
    fontWeight: 'bold',
    padding: '0.5rem',
    position: 'sticky',
    top: 0,
    fontSize: '1.1rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '6px',
    margin: '0 1rem 0.5rem 1rem',
  },
  InvoiceAlign: { margin: '10px 15px' },
};
function mapStateToProps(state) {
  return {
    info: state.InvoiceReducer,
    UserDetail: state.AdminUserReducer,
  };
}

export default connect(mapStateToProps, { PaymentAuthPage, getInvoiceNumber })(withTranslation()(PaymentAuth));
