import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import classes from './User.module.scss';
import Container from '@material-ui/core/Container';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import { Textfield } from './../../components/Textfield';
import { Buttons } from './../../components/Buttons';
import { Topheader } from './../../components/Topheader';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import { UserDetailById, UserEdit, UserResendActivationLink } from '../../actions/AdminUserAction';
import { CompanyLocationDataById } from '../../actions/CompanyAction';
import LoadingOverlay from 'react-loading-overlay';
import { makePhoneNumberForDB } from '../../helpers/util';
import validator from '../../helpers/checkValidations';
import IntegrationDownshift from './../../helpers/multiSelect';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import { validateHtmlMarkUp } from '../../helpers/commonFunction';
import { getValidationStyle, handleChangeCheckbox, getFeeSettingPermissionTitle } from './CommonFunction';
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';

class EditAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      role: '',
      user: JSON.parse(localStorage.getItem('user')),
      redirect: false,
      sideShow: true,
      isLoading: true,
      locationSelected: '',
      misMatchError: [],
      company: {},
      enableBatchTransactionReportNotification: false,
      permissions: {
        refunds: false,
        refundsManual: false,
        textToInvoice: false,
        textInvoice: false,
        textNotifications: false,
        exportRecord: false,
        allLocaton: false,
        creatNewUser: false,
        createInvoice: false,
        ach: false,
        notesVisibilityTerminal: false,
        emailVisibilityTerminal: false,
        virtualTerminal: false,
        recurring: false,
        feeEdit: false,
        editCustomer: false,
        void: false,
        perTransactionLimit: '',
        dailyTransactionLimit: '',
        perRefundLimit: '',
        dailyRefundLimit: '',
        emailNotification: false,
        declineEmailNotification: false,
        deleteInvoice: false,
        viewAllTransactions: false,
        displayProducts: false,
        displayDashboard: false,
        displaySettings: false,
        displayTickets: false,
        displayLocations: false,
        displayUsers: false,
        displayTransactions: false,
      },
      error: {
        UserNameError: false,
        firstNameError: false,
        lastNameError: false,
        emailError: false,
        perTransactionLimitError: false,
        dailyTransactionLimitError: false,
        perRefundLimitError: false,
        dailyRefundLimitError: false,
        phoneError: false,
      },
      marginBottom: '10',
    };
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    this.getUserData();
    this.getAllLocationData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.locationSelected !== this.state.locationSelected) {
      this.updateMarginBottom();
      getValidationStyle(!this.state.locationSelected && this.state.isSubmit, this.state.marginBottom);
    }
  }

  updateMarginBottom() {
    const container = this.containerRef.current;
    if (container.scrollHeight > container.clientHeight) {
      this.setState({ marginBottom: container.scrollHeight });
    }
  }

  getUserData() {
    const {
      match: { params },
    } = this.props;
    this.setState({ isLoading: true });
    this.props.UserDetailById(params).then((res) => {
      this.setState({ ...res.payload.userData.data, isLoading: false });
      let data = this.state.location;
      if (data && data.length > 0)
        this.setState({
          locationSelected: Object.keys(data).map((k, _v) => data[k].id),
        });
    });
  }
  getAllLocationData() {
    let companyId = this.state.user.company._id;
    this.props.CompanyLocationDataById(companyId).then((res) => {
      this.setState({
        locationsData: res.payload.companyData.data.response,
        isLoading: false,
      });
    });
  }
  handleClickMenu(_e) {
    if (this.state.sideShow) this.setState({ sideShow: false });
    else this.setState({ sideShow: true });
  }
  handleClickRefresh() {
    this.getUserData();
  }
  changeHandleCheckbox(event) {
    const permissions = handleChangeCheckbox(event, this.state);
    this.setState({ permissions });
  }
  handleChange(event, _t) {
    let error = {};
    let successMessages = false;
    let misMatchError = [];
    let { id, value } = event.target;
    const isHtmlMarkupValid = validateHtmlMarkUp(value);
    if (isHtmlMarkupValid) {
      return false;
    }
    if (
      id === 'perTransactionLimit' ||
      id === 'dailyTransactionLimit' ||
      id === 'perRefundLimit' ||
      id === 'dailyRefundLimit'
    ) {
      this.setState({
        permissions: { ...this.state.permissions, [id]: value },
      });
    }
    validator(id, value) ? (error[id + 'Error'] = true) : (error[id + 'Error'] = false);
    this.setState({ [id]: value, error, misMatchError, successMessages });
  }

  handleSubmit(e, t) {
    let misMatchError = [];
    let successMessages = false;
    let state = this.state;
    let error = {};
    let errorFlag = false;

    const permissions = this.state.permissions;
    permissions.displaySettings = false;
    this.setState({ permissions });
    Object.keys(state).map((keys, _value) => {
      if (
        keys === 'phone' &&
        !this.state.permissions.textToInvoice &&
        !this.state.permissions.textInvoice &&
        !this.state.permissions.textFlex
      ) {
        return false;
      }
      if (keys === 'password') {
        return false;
      }
      if (validator(keys, state[keys])) {
        return validator(keys, state[keys])
          ? ((error[keys + 'Error'] = true), (errorFlag = true))
          : (error[keys + 'Error'] = false);
      } else {
        return state[keys] === '' || (typeof state[keys] === 'string' && state[keys].trim() === '')
          ? ((error[keys + 'Error'] = true), (errorFlag = true))
          : (error[keys + 'Error'] = false);
      }
    });

    if (state.locationSelected.length === 0) {
      misMatchError.push(t('LocationRequired'));
      errorFlag = true;
      this.setState({ misMatchError });
    }
    if (errorFlag) {
      window.scrollTo(0, 0, 0);
      this.setState({ misMatchError, error, successMessages });
      return false;
    } else {
      if (window.confirm('Are you sure you want to update?')) {
        this.setState({
          isLoading: true,
        });
        this.props.UserEdit({ ...this.state, phone: makePhoneNumberForDB(this.state.phone) }).then((result) => {
          if (result !== undefined && result.type === 'USER_EDIT_DATA') {
            window.scrollTo(0, 0, 0);
            this.setState({ successMessages: true, misMatchError, isLoading: false }, () => {
              setTimeout(() => {
                this.setState({ successMessages: false, redirect: true });
              }, 2000);
            });
          }
          if (result !== undefined && result.type === 'USER_ERROR') {
            this.setState({
              misMatchError: this.props.info.userInfo.data.message,
              isLoading: false,
              successMessages,
            });
          }
        });
      }
    }
  }
  handleSubmitResendLink(_e, _t) {
    let successMessages = false;
    if (window.confirm('Are you sure you want to resend the link?')) {
      this.setState({ isLoading: true });
      this.props.UserResendActivationLink(this.state.email).then((result) => {
        if (result !== undefined && result.type === 'USER_EDIT_DATA') {
          this.setState({ successMessages: true, isLoading: false });
        }
        if (result !== undefined && result.type === 'USER_ERROR') {
          this.setState({
            misMatchError: this.props.editInfo.data.message,
            isLoading: false,
            successMessages,
          });
        }
      });
    }
  }
  addLocation = (arr) => {
    this.setState({ locationSelected: arr });
  };
  render() {
    const { t } = this.props;
    const { redirect } = this.state;
    const currencyTextFieldProps = {
      currencySymbol: '$',
      outputFormat: 'string',
      decimalCharacter: '.',
      digitGroupSeparator: ',',
      textAlign: 'left',
    };
    return (
      <div className="rightPanel">
        {redirect ? <Redirect to="/Users" /> : ''}
        <Topheader />
        <LoadingOverlay active={this.state.isLoading} spinner text="Loading your content...">
          <Container component="main" maxWidth="xl">
            {this.state.misMatchError.length > 0 ? <ErrorMessage errors={[this.state.misMatchError]} /> : ''}
            {this.state.successMessages && this.props.editInfo ? (
              <SuccessMessage
                successes={[
                  this.props.editInfo.data.message
                    ? this.props.editInfo.data.message
                    : 'Something went wrong please try again!',
                ]}
              />
            ) : (
              ''
            )}
            <form className={classes.form} noValidate>
              <Grid container spacing={3}>
                <Grid item xs={6} sm={3}>
                  <Textfield
                    value={this.state.userName}
                    id="userName"
                    type="text"
                    labels={t('Username')}
                    error={this.state.error.userNameError}
                    onChange={(event) => this.handleChange(event, t)}
                    required
                    width
                    focus
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Textfield
                    id="firstName"
                    type="text"
                    labels={t('FirstName')}
                    value={this.state.firstName}
                    required
                    width
                    error={this.state.error.firstNameError}
                    onChange={(event) => this.handleChange(event, t)}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Textfield
                    id="lastName"
                    type="text"
                    labels={t('LastName')}
                    value={this.state.lastName}
                    error={this.state.error.lastNameError}
                    onChange={(event) => this.handleChange(event, t)}
                    required
                    width
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Textfield
                    id="phone"
                    type="text"
                    labels={t('Phone')}
                    required={
                      this.state.permissions.textToInvoice ||
                      this.state.permissions.textInvoice ||
                      this.state.permissions.textFlex ||
                      this.state.phone
                    }
                    error={
                      this.state.permissions.textToInvoice ||
                      this.state.permissions.textInvoice ||
                      this.state.error.phoneError ||
                      this.state.permissions.textFlex
                        ? this.state.error.phoneError
                        : false
                    }
                    onChange={(event) => this.handleChange(event, t)}
                    value={this.state.phone}
                    width
                    focus
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Textfield
                    error={this.state.error.emailError}
                    onChange={(event) => this.handleChange(event, t)}
                    type="email"
                    value={this.state.email}
                    id="email"
                    labels={t('Email')}
                    required
                    width
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl
                    fullWidth
                    style={getValidationStyle(
                      !this.state.locationSelected && this.state.isSubmit,
                      this.state.marginBottom
                    )}
                    ref={this.containerRef}
                  >
                    <IntegrationDownshift
                      isLocationExists={true}
                      selectedLocations={this.state.location}
                      locationData={
                        this.state.user.role === 1 || this.state.user.role === 2 || this.state.user.role === 3
                          ? this.state.locationsData
                          : ''
                      }
                      addLocation={this.addLocation}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <CurrencyTextField
                    error={this.state.error.perTransactionLimitError}
                    required
                    style={{ width: '100%' }}
                    placeholder="0.00"
                    type="text"
                    id="perTransactionLimit"
                    value={this.state.permissions.perTransactionLimit}
                    width
                    onChange={(event) => this.handleChange(event, t)}
                    label={t('PerTransactionLimit')}
                    {...currencyTextFieldProps}
                    onBlur={(event, value) => {
                      this.setState({
                        ...this.state,
                        perTransactionLimit: value,
                        permissions: {
                          ...this.state.permissions,
                          perTransactionLimit: value,
                        },
                      });
                    }}
                    onFocus={(event, value) => {
                      this.setState({
                        ...this.state,
                        permissions: {
                          ...this.state.permissions,
                          perTransactionLimit: value,
                        },
                        perTransactionLimit: value,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <CurrencyTextField
                    id="dailyTransactionLimit"
                    type="text"
                    placeholder="0.00"
                    {...currencyTextFieldProps}
                    error={this.state.error.dailyTransactionLimitError}
                    label={t('DailyTransactionLimit')}
                    value={this.state.permissions.dailyTransactionLimit}
                    onChange={(event) => this.handleChange(event, t)}
                    style={{ width: '100%' }}
                    onFocus={(event, value) => {
                      this.setState({
                        ...this.state,
                        permissions: {
                          ...this.state.permissions,
                          dailyTransactionLimit: value,
                        },
                        dailyTransactionLimit: value,
                      });
                    }}
                    required
                    onBlur={(event, value) => {
                      this.setState({
                        ...this.state,
                        permissions: {
                          ...this.state.permissions,
                          dailyTransactionLimit: value,
                        },
                        dailyTransactionLimit: value,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <CurrencyTextField
                    id="perRefundLimit"
                    required
                    type="text"
                    error={this.state.error.perRefundLimitError}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    width
                    value={this.state.permissions.perRefundLimit}
                    placeholder="0.00"
                    onChange={(event) => this.handleChange(event, t)}
                    label={t('PerRefundLimit')}
                    {...currencyTextFieldProps}
                    onBlur={(event, value) => {
                      this.setState({
                        ...this.state,
                        permissions: {
                          ...this.state.permissions,
                          perRefundLimit: value,
                        },
                        perRefundLimit: value,
                      });
                    }}
                    style={{ width: '100%' }}
                    onFocus={(event, value) => {
                      this.setState({
                        ...this.state,
                        perRefundLimit: value,
                        permissions: {
                          ...this.state.permissions,
                          perRefundLimit: value,
                        },
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <CurrencyTextField
                    required
                    error={this.state.error.dailyRefundLimitError}
                    value={this.state.permissions.dailyRefundLimit}
                    onChange={(event) => this.handleChange(event, t)}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    type="text"
                    id="dailyRefundLimit"
                    placeholder="0.00"
                    style={{ width: '100%' }}
                    width
                    label={t('DailyRefundLimit')}
                    {...currencyTextFieldProps}
                    onFocus={(event, value) => {
                      this.setState({
                        ...this.state,
                        permissions: {
                          ...this.state.permissions,
                          dailyRefundLimit: value,
                        },
                        dailyRefundLimit: value,
                      });
                    }}
                    onBlur={(event, value) => {
                      this.setState({
                        ...this.state,
                        permissions: {
                          ...this.state.permissions,
                          dailyRefundLimit: value,
                        },
                        dailyRefundLimit: value,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={3} style={{ marginTop: 20 }}>
                  <label>
                    <Checkbox
                      id="enableBatchTransactionEmailNotification"
                      color="primary"
                      onChange={(event) => {
                        this.setState({ enableBatchTransactionReportNotification: event.target.checked });
                      }}
                      checked={this.state.enableBatchTransactionReportNotification}
                      value={this.state.enableBatchTransactionReportNotification}
                      inputProps={{
                        'aria-label': 'secondary checkboxes',
                      }}
                    />
                    <span>{t('BatchTransactionNotification')}</span>
                  </label>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={8}>
                    {' '}
                    <h5>Permissions</h5>
                  </Grid>
                  <Grid container item xs={12}>
                    {this.state.role < 4 ? (
                      <Grid item xs={12} sm={6}>
                        <label>
                          <Checkbox
                            id="creatNewUser"
                            labels="creatNewUser"
                            onChange={(event) => {
                              this.changeHandleCheckbox(event);
                            }}
                            value={this.state.permissions.creatNewUser}
                            checked={this.state.permissions.creatNewUser}
                            color="primary"
                            inputProps={{
                              'aria-label': 'secondary checkbox',
                            }}
                          />
                          <span>Create New User</span>
                        </label>
                      </Grid>
                    ) : (
                      ''
                    )}
                    <Grid item xs={12} sm={6}>
                      <label>
                        <Checkbox
                          id="refunds"
                          checked={this.state.permissions.refunds}
                          onChange={(event) => {
                            this.changeHandleCheckbox(event);
                          }}
                          color="primary"
                          value={this.state.permissions.refunds}
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                        />
                        <span>Refunds</span>
                      </label>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <label>
                        <Checkbox
                          checked={this.state.permissions.editCustomer}
                          id="editCustomer"
                          onChange={(e) => {
                            this.changeHandleCheckbox(e);
                          }}
                          value={this.state.permissions.editCustomer}
                          color="primary"
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                        />
                        <span>Edit Customer</span>
                      </label>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <label>
                        <Checkbox
                          id="refundsManual"
                          value={this.state.refundsManual}
                          onChange={(event) => {
                            this.changeHandleCheckbox(event);
                          }}
                          checked={this.state.permissions.refundsManual}
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                          color="primary"
                        />
                        <span>Refunds Manual</span>
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <label>
                        <Checkbox
                          checked={this.state.permissions.allLocaton}
                          id="allLocaton"
                          onChange={(event) => {
                            this.changeHandleCheckbox(event);
                          }}
                          color="primary"
                          value={this.state.permissions.allLocaton}
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                        />
                        <span>All Location</span>
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <label>
                        <Checkbox
                          checked={this.state.permissions.createInvoice}
                          id="createInvoice"
                          onChange={(event) => {
                            this.changeHandleCheckbox(event);
                          }}
                          color="primary"
                          value={this.state.permissions.createInvoice}
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                        />
                        <span>Invoicing</span>
                      </label>
                    </Grid>
                    {this.state.company.ACH && (
                      <Grid item xs={12} sm={6}>
                        <label>
                          <Checkbox
                            id="ach"
                            checked={this.state.permissions.ach}
                            onChange={(event) => {
                              this.changeHandleCheckbox(event);
                            }}
                            color="primary"
                            value={this.state.permissions.ach}
                            inputProps={{
                              'aria-label': 'secondary checkbox',
                            }}
                          />
                          <span>ACH </span>
                        </label>
                      </Grid>
                    )}
                    <Grid item xs={12} sm={6}>
                      <label>
                        <Checkbox
                          id="virtualTerminal"
                          checked={this.state.permissions.virtualTerminal}
                          onChange={(event) => {
                            this.changeHandleCheckbox(event);
                          }}
                          color="primary"
                          value={this.state.permissions.virtualTerminal}
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                        />
                        <span>Virtual Terminal</span>
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <label>
                        <Checkbox
                          checked={this.state.permissions.recurring}
                          id="recurring"
                          onChange={(event) => {
                            this.changeHandleCheckbox(event);
                          }}
                          color="primary"
                          value={this.state.permissions.recurring}
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                        />
                        <span>Recurring Billing</span>
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <label>
                        <Checkbox
                          checked={this.state.permissions.feeEdit}
                          id="feeEdit"
                          onChange={(event) => {
                            this.changeHandleCheckbox(event);
                          }}
                          color="primary"
                          value={this.state.permissions.feeEdit}
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                        />
                        <span>{getFeeSettingPermissionTitle(this.state.role)}</span>
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <label>
                        <Checkbox
                          id="exportRecord"
                          checked={this.state.permissions.exportRecord}
                          onChange={(e) => {
                            this.changeHandleCheckbox(e);
                          }}
                          color="primary"
                          value={this.state.permissions.exportRecord}
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                        />
                        <span>Export Records</span>
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <label>
                        <Checkbox
                          id="void"
                          checked={this.state.permissions.void}
                          onChange={(event) => {
                            this.changeHandleCheckbox(event);
                          }}
                          color="primary"
                          value={this.state.permissions.void}
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                        />
                        <span>Void</span>
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <label>
                        <Checkbox
                          checked={this.state.permissions.emailNotification}
                          id="emailNotification"
                          onChange={(event) => {
                            this.changeHandleCheckbox(event);
                          }}
                          color="primary"
                          value={this.state.permissions.emailNotification}
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                        />
                        <span>{t('emailNotifications')}</span>
                      </label>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <label>
                        <Checkbox
                          id="deleteInvoice"
                          checked={this.state.permissions.deleteInvoice}
                          onChange={(event) => {
                            this.changeHandleCheckbox(event);
                          }}
                          color="primary"
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                          value={this.state.deleteInvoice}
                        />
                        <span>{t('DeleteInvoice')}</span>
                      </label>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <label>
                        <Checkbox
                          checked={this.state.permissions.declineEmailNotification}
                          id="declineEmailNotification"
                          onChange={(event) => {
                            this.changeHandleCheckbox(event);
                          }}
                          color="primary"
                          value={this.state.permissions.declineEmailNotification}
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                        />
                        <span>{t('declineEmailNotifications')}</span>
                      </label>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <label>
                        <Checkbox
                          value={this.state.permissions.viewAllTransactions}
                          id="viewAllTransactions"
                          onChange={(event) => {
                            this.changeHandleCheckbox(event);
                          }}
                          checked={this.state.permissions.viewAllTransactions}
                          color="primary"
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                        />
                        <span>View All Transactions</span>
                      </label>
                    </Grid>

                    {this.state.role > 4 ? (
                      <Grid item xs={12} sm={6}>
                        <Checkbox
                          color="primary"
                          id="creatNewUser"
                          onChange={(event) => {
                            this.changeHandleCheckbox(event);
                          }}
                          checked={this.state.permissions.creatNewUser}
                          value={this.state.permissions.creatNewUser}
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                        />
                        <label>
                          <span>Create New User</span>
                        </label>
                      </Grid>
                    ) : (
                      ''
                    )}
                    <Grid item xs={12} sm={6}>
                      <Grid item xs={12} sm={6}>
                        <label>
                          <Checkbox
                            color="primary"
                            id="textToInvoice"
                            onChange={(e) => {
                              this.changeHandleCheckbox(e);
                            }}
                            value={this.state.permissions.textToInvoice}
                            checked={this.state.permissions.textToInvoice}
                            inputProps={{
                              'aria-label': 'secondary checkbox',
                            }}
                          />
                          <span>Texting</span>
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={6} style={{ marginLeft: 10 }}>
                        <label>
                          <Checkbox
                            color="primary"
                            id="textNotifications"
                            value={this.state.permissions.textNotifications}
                            onChange={(e) => {
                              this.changeHandleCheckbox(e);
                            }}
                            checked={this.state.permissions.textNotifications}
                            inputProps={{
                              'aria-label': 'secondary checkbox',
                            }}
                          />
                          <span>Text Notifications</span>
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={6} style={{ marginLeft: 10 }}>
                        <label>
                          <Checkbox
                            checked={this.state.permissions.textInvoice}
                            value={this.state.permissions.textInvoice}
                            id="textInvoice"
                            color="primary"
                            onChange={(e) => {
                              this.changeHandleCheckbox(e);
                            }}
                            inputProps={{
                              'aria-label': 'secondary checkbox',
                            }}
                          />
                          <span>Text Invoice</span>
                        </label>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>{' '}
                <h4>Terminal Field Display</h4>
                <Grid container item xs={12}>
                  <Grid item xs={12} sm={6}>
                    <label>
                      <Checkbox
                        id="emailVisibilityTerminal"
                        color="primary"
                        onChange={(event) => {
                          this.changeHandleCheckbox(event);
                        }}
                        checked={this.state.permissions.emailVisibilityTerminal}
                        value={this.state.permissions.emailVisibilityTerminal}
                        inputProps={{
                          'aria-label': 'secondary checkboxes',
                        }}
                      />
                      <span>Email</span>
                    </label>
                    <label>
                      <Checkbox
                        id="notesVisibilityTerminal"
                        color="primary"
                        onChange={(event) => {
                          this.changeHandleCheckbox(event);
                        }}
                        value={this.state.notesVisibilityTerminal}
                        checked={this.state.permissions.notesVisibilityTerminal}
                        inputProps={{
                          'aria-label': 'secondary checkboxes',
                        }}
                      />
                      <span>Notes</span>
                    </label>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <h5>Main Menu Display</h5>
                  <Grid container item xs={12}>
                    <Grid item xs={12} sm={6}>
                      <label>
                        <Checkbox
                          id="displayDashboard"
                          onChange={(e) => {
                            this.changeHandleCheckbox(e);
                          }}
                          checked={this.state.permissions.displayDashboard}
                          value={this.state.permissions.displayDashboard}
                          color="primary"
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                        />
                        <span>Dashboard</span>
                      </label>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12}>
                    <Grid item xs={12} sm={6}>
                      <label>
                        <Checkbox
                          id="displayTickets"
                          onChange={(e) => {
                            this.changeHandleCheckbox(e);
                          }}
                          checked={this.state.permissions.displayTickets}
                          value={this.state.permissions.displayTickets}
                          color="primary"
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                        />
                        <span>Tickets</span>
                      </label>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12}>
                    <Grid item xs={12} sm={6}>
                      <label>
                        <Checkbox
                          id="displayLocations"
                          onChange={(e) => {
                            this.changeHandleCheckbox(e);
                          }}
                          checked={this.state.permissions.displayLocations}
                          value={this.state.permissions.displayLocations}
                          color="primary"
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                        />
                        <span>Locations</span>
                      </label>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12}>
                    <Grid item xs={12} sm={6}>
                      <label>
                        <Checkbox
                          id="displayTransactions"
                          onChange={(e) => {
                            this.changeHandleCheckbox(e);
                          }}
                          checked={this.state.permissions.displayTransactions}
                          value={this.state.permissions.displayTransactions}
                          color="primary"
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                        />
                        <span>Transactions</span>
                      </label>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12}>
                    <Grid item xs={12} sm={6}>
                      <label>
                        <Checkbox
                          id="displayProducts"
                          onChange={(e) => {
                            this.changeHandleCheckbox(e);
                          }}
                          checked={this.state.permissions.displayProducts}
                          value={this.state.permissions.displayProducts}
                          color="primary"
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                        />
                        <span>Products</span>
                      </label>
                    </Grid>
                  </Grid>
                  {this.state.role === 3 ? (
                    <Grid container item xs={12}>
                      <Grid item xs={12} sm={6}>
                        <label>
                          <Checkbox
                            id="displayUsers"
                            onChange={(e) => {
                              this.changeHandleCheckbox(e);
                            }}
                            checked={this.state.permissions.displayUsers}
                            value={this.state.permissions.displayUsers}
                            color="primary"
                            inputProps={{
                              'aria-label': 'secondary checkbox',
                            }}
                          />
                          <span>Users</span>
                        </label>
                      </Grid>
                    </Grid>
                  ) : (
                    ''
                  )}
                </Grid>
                <Buttons
                  id="signUp"
                  type="button"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={(event) => this.handleSubmit(event, t)}
                  text={t('Update')}
                />
                <Buttons
                  className={classes.ml_2}
                  variant="contained"
                  color="secondary"
                  text={t('Back')}
                  onClick={this.props.history.goBack}
                />
                <Buttons
                  className={classes.ml_2}
                  variant="contained"
                  color="primary"
                  text={t('ResendLink')}
                  onClick={(event) => this.handleSubmitResendLink(event, t)}
                />
              </Grid>
            </form>
          </Container>
        </LoadingOverlay>
      </div>
    );
  }
}
EditAdmin.propTypes = {
  t: PropTypes.func,
  UserDetailById: PropTypes.func,
  editInfo: PropTypes.object,
  info: PropTypes.any,
  history: PropTypes.object,
  match: PropTypes.object,
  UserEdit: PropTypes.func,
  CompanyLocationDataById: PropTypes.func,
  UserResendActivationLink: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    info: state.AdminUserReducer,
    editInfo: state.AdminUserReducer.editInfo,
  };
}

export default connect(mapStateToProps, {
  UserDetailById,
  UserEdit,
  UserResendActivationLink,
  CompanyLocationDataById,
})(withTranslation()(EditAdmin));
