import React, { useState } from 'react';
import { formatCurrency } from '../../../helpers/commonFunction';
import { Grid, Typography, Button, Box } from '@mui/material';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import TableBody from '@material-ui/core/TableBody';
import { Table, TableRow } from '@material-ui/core';
import { tableCellClasses } from '@mui/material/TableCell';
import MuiTableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core/styles';
import config from '../../../constants/config';
import { useDispatch } from 'react-redux';
import { getInvoiceByInvoiceNumber } from '../../../actions/RequestFormAction';
import PrintReceipt from '../../../helpers/PrintReceipt';
import { CloseableAlerts } from './../../../components/Message/CloseableAlerts';
import { useTranslation } from 'react-i18next';
const TableCell = withStyles({
  root: {
    borderBottom: 'none',
    padding: '5px',
  },
})(MuiTableCell);

const PaymentConfirmation = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [errorMessage, setErrorMessage] = useState('');

  const downloadInvoicePDF = () => {
    setErrorMessage('');
    if (!props?.invoice) {
      return;
    }
    const path = `${config.BASEURL}downloadInvoiceFile/${props?.invoice}`;
    fetch(path)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `invoice_${props?.invoice}.pdf`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        setErrorMessage(error);
      });
  };

  const downLoadReceiptPDF = () => {
    setErrorMessage('');
    const invoice = props?.invoice;
    dispatch(getInvoiceByInvoiceNumber(invoice)).then((result) => {
      const {
        payload: { value },
      } = result || {};
      if (value) {
        const receiptData = {
          name: `${props.customerData.firstName} ${props.customerData.lastName}`,
          transactionId: props.paymentResponse?.transactionId,
          customerName: props.customerData?.firstName + ' ' + props.customerData?.lastName,
          paymentType: props.selectedOption === 'card' ? props?.card?.cardType : 'ACH',
          dateOpened: props?.paymentResponse?.date,
          timestamp_PAID: value.timestamp_PAID || new Date(),
          companyLogoUrl: config.PAYTRACE_AWS_PATH + (props?.location?.image || 'new-logo.png'),
          invoiceOrJobId: invoice,
          ...(props.selectedOption === 'card' && { cardType: props.card?.cardType }),
          isCardNumberAvailable: props?.paymentResponse?.maskCard
            ? props.selectedOption === 'card' &&
              (props.paymentType.toUpperCase() === 'DEBIT' || props.paymentType.toUpperCase() === 'CREDIT')
            : false,
          isCardTypeAvailable: !!(props?.card?.cardType && props.paymentType === 'card'),
          subtTotal: props.amount,
          isSurchargeApplied: !!(
            props.fees.surcharge &&
            props.fees.surcharge !== '0' &&
            props.paymentType.toUpperCase() === 'CREDIT' &&
            props.locationSettings?.InvoiceSurcharge
          ),
          surchargeFieldName: props.locationSettings?.InvoiceSurchargeFeeName
            ? props.locationSettings?.InvoiceSurchargeFeeName
            : 'Surcharge Fee',
          surchargeAmount: props.fees.surcharge,
          tax: props.fees.tax,
          svcFee: Number(props.fees.svcFee || 0),
          STATUS: 'Paid',
          isProducesAvailable: value?.Products && value?.Products.length > 0,
          products: value?.Products,
          isDualFeeEnabled: props.locationSettings?.DualFeeEnabled,
          total: props.total,
          cashTotal: props.amount + props.fees.tax,
          publicNotes: value.publicNotes,
          locationId: { locationName: props.location.locationName },
          customInvoiceName: props.location.customInvoiceName,
          maskedCardNumber: props?.paymentResponse?.maskCard,
          subTotal: getSubTotalAmount(),
        };
        PrintReceipt(receiptData);
      } else {
        setErrorMessage('Error occurred while downloading Receipt');
      }
    });
  };
  const getTotalName = () => {
    if (props.selectedOption === 'card') {
      if (props.locationSettings?.DualFeeEnabled) {
        return 'Card';
      }
    }
    return '';
  };
  const getSubTotalAmount = () => {
    const isDualFeeEnabled = props.locationSettings?.DualFeeEnabled;
    const isCardSelected = props.selectedOption === 'card';
    let subTotalAmount = Number(props.amount || 0);
    if (isDualFeeEnabled && isCardSelected) {
      subTotalAmount += Number(props.fees.svcFee || 0);
    }
    return subTotalAmount;
  };

  const isSubTotal = () => {
    return (
      props.locationSettings?.InvoiceSurcharge ||
      props.locationSettings?.InvoiceDiscount ||
      props.locationSettings?.DualFeeEnabled
    );
  };
  const removeCloseableMessage = (index) => {
    setErrorMessage('');
  };

  const getMaskAccountNumber = (accountNumber) => {
    // Check if the input is at least 4 characters long
    if (accountNumber.length <= 4) {
      return accountNumber; // Nothing to mask if it's already 4 or fewer characters
    }

    // Replace all but the last 4 characters with 'x'
    const maskedAccountNumber = 'x'.repeat(accountNumber.length - 4) + accountNumber.slice(-4);
    return maskedAccountNumber;
  };

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={12} md={8} lg={6}>
        {errorMessage && (
          <CloseableAlerts
            key="error-alert"
            message={errorMessage}
            severity="error"
            sx={{ width: '100%', marginBottom: 2 }}
            closer={removeCloseableMessage}
          />
        )}
        <Box p={{ xs: 2, md: 3 }} borderRadius={2}>
          <Typography variant="h4" component="div" align="center" color="#28a745" gutterBottom>
            Payment Successful!
          </Typography>
          <Typography variant="h5" component="div" align="center" gutterBottom mt={4}>
            {props.location.locationName}
          </Typography>
          <Box mt={2} mb={4}>
            <Table
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  border: 'none !important',
                },
              }}
              size="medium"
              aria-label="a dense table"
            >
              <TableBody sx={{ border: 'none' }}>
                <TableRow id="invoice-number-row">
                  <TableCell align="left">Invoice Number</TableCell>
                  <TableCell align="right" style={{ overflowWrap: 'anywhere' }}>
                    {props.invoice}
                  </TableCell>
                </TableRow>
                <TableRow id="transaction-number-row">
                  <TableCell align="left">Transaction ID</TableCell>
                  <TableCell align="right" style={{ overflowWrap: 'anywhere' }}>
                    {props?.paymentResponse?.transactionId}
                  </TableCell>
                </TableRow>
                <TableRow id="customer-name-row">
                  <TableCell align="left">Customer Name</TableCell>
                  <TableCell align="right" style={{ overflowWrap: 'anywhere' }}>
                    {`${props.customerData.firstName} ${props.customerData.lastName}`}
                  </TableCell>
                </TableRow>
                <TableRow id="payment-type-row">
                  <TableCell align="left">Payment Type</TableCell>
                  <TableCell align="right" style={{ overflowWrap: 'anywhere' }}>
                    {props.selectedOption === 'card' ? props?.card?.cardType : 'ACH'}
                  </TableCell>
                </TableRow>
                <TableRow id="card-number-row">
                  <TableCell align="left">
                    {props.selectedOption === 'card' ? 'Card Number' : 'Account Number'}
                  </TableCell>
                  <TableCell align="right" style={{ overflowWrap: 'anywhere' }}>
                    {props.selectedOption === 'card'
                      ? props?.paymentResponse?.maskCard
                      : getMaskAccountNumber(props?.ach?.accountNumber || props.paymentResponse.accountNumber)}
                  </TableCell>
                </TableRow>
                <TableRow id="date-row">
                  <TableCell align="left">Date</TableCell>
                  <TableCell align="right" style={{ overflowWrap: 'anywhere' }}>
                    {format(props?.paymentResponse?.date || new Date(), 'MM/dd/yyyy')}
                  </TableCell>
                </TableRow>
                <TableRow id="amount-row">
                  <TableCell align="left">{isSubTotal() ? `${getTotalName()} Subtotal` : 'Base Amount'}</TableCell>
                  <TableCell align="right">{formatCurrency(getSubTotalAmount())}</TableCell>
                </TableRow>
                {props.locationSettings?.InvoiceDiscount &&
                  !props.locationSettings?.DualFeeEnabled &&
                  props.selectedOption === 'card' && (
                    <TableRow id="discount-fee-row">
                      <TableCell align="left">
                        {props.locationSettings?.DiscountFeeName ? props.locationSettings?.DiscountFeeName : 'Svc Fee'}
                      </TableCell>
                      <TableCell align="right">{formatCurrency(props.fees.svcFee)}</TableCell>
                    </TableRow>
                  )}
                {props.locationSettings?.InvoiceSurcharge &&
                  props.selectedOption === 'card' &&
                  props.paymentType &&
                  props.paymentType.toUpperCase() !== 'DEBIT' && (
                    <TableRow id="surcharge-row">
                      <TableCell align="left">Surcharge Fee</TableCell>
                      <TableCell align="right">{formatCurrency(props.fees.surcharge)}</TableCell>
                    </TableRow>
                  )}
                {props.locationSettings?.InvoiceTax && (
                  <TableRow id="sales-tax-row">
                    <TableCell align="left">Sales Tax</TableCell>
                    <TableCell align="right">{formatCurrency(props.fees.tax)}</TableCell>
                  </TableRow>
                )}
                <TableRow id="total-row">
                  <TableCell align="left">{`${getTotalName()} Total`}</TableCell>
                  <TableCell align="right">{props.total ? formatCurrency(props.total) : '$0.00'}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Button
              id="download-receipt-btn"
              variant="contained"
              color="primary"
              sx={{ mb: 2, width: { xs: '100%', sm: '300px' } }}
              onClick={downLoadReceiptPDF}
            >
              {t('DownloadReceipt')}
            </Button>
            <Button
              id="download-invoice-btn"
              variant="outlined"
              color="primary"
              sx={{ width: { xs: '100%', sm: '300px' } }}
              onClick={downloadInvoicePDF}
            >
              {t('DownloadInvoice')}
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
PaymentConfirmation.propTypes = {
  invoice: PropTypes.string.isRequired,
  location: PropTypes.shape({
    locationName: PropTypes.string.isRequired,
    customInvoiceName: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
  }).isRequired,
  paymentType: PropTypes.string.isRequired, //Payment is DEBIT OR CREDIT
  selectedOption: PropTypes.string.isRequired, //Payment selected Option {Card or Bank Transfer}
  total: PropTypes.number.isRequired,
  amount: PropTypes.number.isRequired,
  locationSettings: PropTypes.object.isRequired,
  customerData: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  }).isRequired,
  paymentResponse: PropTypes.shape({
    date: PropTypes.object,
    maskCard: PropTypes.string,
    transactionId: PropTypes.string,
    accountNumber: PropTypes.string,
  }),
  ach: PropTypes.shape({
    isOpen: PropTypes.bool,
    accountName: PropTypes.string,
    accountNumber: PropTypes.string,
    routingNumber: PropTypes.string,
  }),
  fees: PropTypes.shape({
    svcFee: PropTypes.string.isRequired,
    surcharge: PropTypes.string.isRequired | PropTypes.number.isRequired,
    tax: PropTypes.string.isRequired,
  }),
  card: PropTypes.shape({
    cardNumber: PropTypes.string,
    cardType: PropTypes.string,
  }),
};
export default PaymentConfirmation;
