import React, { Component } from 'react';
import { changeFocus } from '../../helpers/changeFocusCard';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Topheader } from './../../components/Topheader';
import { CustomerDetailsId, editCustomer } from '../../actions/CustomerAction';
import autoFillMultipassFields from '../../helpers/functions/autoFillCardFields';
import PropTypes from 'prop-types';
import { setupPaymentGatewayProperties } from '../../helpers/functions/paymentGateway.properties.js';
import { getCsrfToken } from '../../actions/PaymentAction';
import { Textfield } from './../../components/Textfield';
import { Buttons } from './../../components/Buttons';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import classes from './Customers.module.scss';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import LoadingOverlay from 'react-loading-overlay';
import validator from '../../helpers/checkValidations';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import MaskedInput from 'react-maskedinput';
import { makePhoneNumberForDB, isAddressInputValid } from '../../helpers/util';
import { Checkbox, CardActions } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import { validateZipCode } from '../../helpers/functions/validateZipCode';
import { validateCardValues, getLocationPaymentGateway } from '../../helpers/commonFunction';
import { ALL_LOCATION_ERROR } from './../../constants/message';
import { changeHandler, validateInputFields } from './CommonFunction';
import { UserDetailById } from '../../actions/AdminUserAction';
import { setTsepAuth } from '../../actions/LocationAction';
import { rules } from '../../constants/IntakeFormConstants';
import config from '../../constants/config';
import { checkCreditOrDebit } from '../../helpers/PostDataApi';
import AddressFieldsDropDown from '../../components/AddressFieldsDropDown/AddressFieldsDropDown';
import { checkAddressEnabled } from '../../helpers/functions/addressFieldsEnabled';
import { handleAddressSettingError } from '../../helpers/functions/validateAddressfields';
import { initialAddressSettings, initialAddressState } from '../../helpers/addressSettingsState';
import TsepCard from '../Terminal/components/TsepCard';
import Tooltip from '@mui/material/Tooltip';

class EditCustomers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      sideShow: true,
      REFUND: false,
      isLoading: true,
      isSubmit: false,
      customer_receipt: false,
      customerPortal: false,
      noCard: false,
      updateCard: true,
      isActivated: false,
      userName: '',
      firstName: '',
      lastName: '',
      createdBy: '',
      companyId: '',
      cardWalletID: null,
      tsepFieldError: null,
      tsepFieldSuccess: false,
      tsepSuccessResponse: null,
      tsepAuth: false,
      email: '',
      phone: '',
      customer_id: '',
      userDetails: JSON.parse(localStorage.getItem('user')),
      customFields: [],
      StartDate: new Date(),
      misMatchError: [],
      cardPanelShow: true,
      addNewCard: false,
      csrf: { 'csrf-token': '', 'csrf-secret': '' },
      isProcessWithoutCard: false,
      paytraceCredentials: {},
      customerUserCreation: false,
      isNotResendLink: true,
      card: {
        cardHolderName: '',
        cardNumber: '_______________',
        cardExpiryDate: '__/____',
        cardCsc: '___',
        customerToken: false,
        customerTokenUpdate: false,
      },
      error: {
        customer_idError: false,
        amountError: false,
        phoneError: false,
        emailError: false,
        zipError: false,
        addressError: false,
      },
      errorCard: {
        cardNumber: false,
        cardHolderName: false,
        cardExpiryDate: false,
        cardCsc: false,
      },
      ifMobile: window.innerWidth < 768,
      ...initialAddressState,
      ...initialAddressSettings,
    };
    this.expiryDateRef = React.createRef();
    this.cscCardRef = React.createRef();
    this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
    this.handleSubmitAddCard = this.handleSubmitAddCard.bind(this);
    this.handleSubmitAddCardTsep = this.handleSubmitAddCardTsep.bind(this);
  }
  componentDidMount() {
    const paymentGateway = getLocationPaymentGateway();
    this.setPaymentGatewayProperties(paymentGateway);
    this.fetchAddressDetaills();
    this.getCustomerDetails();
    this.getCsrfTokens();
    this.fetchUserDetailsData();
    this.checkMobileSize();
    this.checkAllLocation();
    this.injectEventListeners();
    this.setUserData();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkMobileSize);
  }

  setUserData() {
    let userData = JSON.parse(localStorage.getItem('user'));
    this.setState({ createdBy: userData._id, companyId: userData.company._id, isLoading: false });

    const locationPaydetails = JSON.parse(localStorage.getItem('locationPaydetails'));
    if (locationPaydetails.customerPortal) {
      this.setState({ customerPortal: locationPaydetails.customerPortal });
    }
  }

  injectEventListeners() {
    window.addEventListener('resize', this.checkMobileSize.bind(this));
    document.addEventListener('tsepEventUpdate', this.handleTsepEventUpdated);
  }

  checkAllLocationSelected() {
    const selectedLocation = JSON.parse(localStorage.getItem('selected'));
    const userDetails = JSON.parse(localStorage.getItem('user'));
    if (selectedLocation === 0 && userDetails.permissions.allLocaton) {
      this.setState({ allLocationError: true });
      return true;
    } else {
      this.setState({ allLocationError: false });
      return false;
    }
  }

  checkAllLocation() {
    let misMatchErrors = [];
    if (this.checkAllLocationSelected()) {
      misMatchErrors.push(ALL_LOCATION_ERROR);
      this.setState({ misMatchErrors, allLocationError: true });
    } else {
      this.setState({ allLocationError: false });
    }
  }

  setPaymentGatewayProperties(paymentGateway) {
    const { misMatchErrors, paytraceCredentials, multipassCredentials } = setupPaymentGatewayProperties(paymentGateway);
    if (misMatchErrors) {
      this.setState({ misMatchErrors });
    } else {
      paymentGateway === 'paytrace' ? this.setState({ paytraceCredentials }) : this.setState({ multipassCredentials });
    }
  }

  checkMobileSize() {
    const mobileSize = 768; // Set the threshold for mobile size (adjust as needed)
    this.setState({ ifMobile: window.innerWidth < mobileSize });
  }

  handleTsepEventUpdated = (event) => {
    const tsepEvent = event.detail;
    if (tsepEvent.eventType === 'FieldValidationErrorEvent') {
      this.setState({ tsepFieldError: tsepEvent.event.message });
    } else if (tsepEvent.eventType === 'TokenEvent' && tsepEvent.event.status === 'PASS') {
      this.setState(
        { tsepSuccessResponse: { ...tsepEvent.event }, tsepFieldSuccess: true, tsepFieldError: null },
        () => {
          const saveCustomer = document.getElementById('saveCustomer');
          saveCustomer.disabled = false;
        }
      );
    } else if (tsepEvent.eventType === 'TokenEvent' && tsepEvent.event.status === 'FAILURE') {
      this.setState({ tsepFieldError: tsepEvent.event.message, tsepSuccessResponse: null, tsepFieldSuccess: false });
    } else if (tsepEvent.eventType === 'FocusOutEvent' && tsepEvent.event.fieldName === 'tsep-cardNum') {
      this.setTsepCardPaymentType();
    } else if (
      tsepEvent.eventType === 'ErrorEvent' &&
      tsepEvent.event.status === 'FAIL' &&
      tsepEvent.event.responseCode === 'TSEPERR911'
    ) {
      this.props.setTsepAuth(false);
    }
    console.log('Tsep catched event:', tsepEvent);
  };

  checkCardPaymentType = (cardValue) => {
    checkCreditOrDebit(config.BIN_CHECKER_URL, config.BIN_API_KEY, cardValue.substring(0, 6))
      .then((data) => {
        this.setState({
          card: {
            ...this.state.card,
            customerToken: true,
            cardType: data?.data?.card,
            creditOrDebit: data.data.type ? data.data.type : null,
            binCodesResponse: data.data,
          },
          creditOrDebit: data.data.type ? data.data.type : null,
        });
      })
      .catch((err) => {
        console.log('---error', err);
      });
  };

  setTsepCardPaymentType = () => {
    const cardNumberElement = document.getElementById('tsep-cardNum');
    if (cardNumberElement) {
      const cardNumberValue = cardNumberElement?.value.replace(/\s/g, '');
      this.checkCardPaymentType(cardNumberValue);
    }
  };

  fetchUserDetailsData() {
    this.setState({ isLoading: true });
    let userData = JSON.parse(localStorage.getItem('user'));
    this.props.UserDetailById({ id: userData._id }).then((res) => {
      if (res.type === 'USER_SUCCESS' && res.payload.userData.status === 200) {
        this.setState({
          isLoading: false,
          settings: res.payload.userData.data.settings,
          userDetails: res.payload.userData.data,
        });
      }
    });
  }
  getCsrfTokens() {
    this.props.getCsrfToken().then((res) => {
      this.setState({ csrf: { ...res.payload.paymentData.data } });
    });
  }
  handleChange(event, _t) {
    const { id, value, misMatchError, error, validationMarkup } = changeHandler(event);
    if (!validationMarkup) {
      return false;
    }
    this.setState({ [id]: value, error, misMatchError, isSubmit: false, successMessages: false });
  }

  checkCardValues(state) {
    const { cardNumberValidation, cardExpiryValidation, cardCscValidation, errorCard } = validateCardValues(
      this.state,
      state
    );
    if (!cardNumberValidation || !cardCscValidation || !cardExpiryValidation) {
      this.setState({ errorCard });
      return false;
    } else {
      return true;
    }
  }

  getCustomerDetails() {
    const {
      match: { params },
    } = this.props;
    this.setState({ isLoading: true });

    this.props.CustomerDetailsId(params).then((res) => {
      let transaction = res.payload.customerData.data;
      if (transaction.success) {
        this.setState({ ...transaction.response, isLoading: false });
      }
      if (this.state.masked_card_number) {
        this.setState({ addNewCard: true });
      }
    });
  }

  inputValidation(key, state, value) {
    if (key === value && state[value] == '') {
      return false;
    }
    return true;
  }

  checkTokenUpdate() {
    return this.state.card.customerTokenUpdate || this.state.card.customerToken;
  }
  checkTsepFieldErrors(misMatchError, errorFlag) {
    if (this.state.tsepFieldError) {
      window.scrollTo(0, 0, 0);
      misMatchError.push(this.state.tsepFieldError);
      this.setState({ misMatchError });
      errorFlag = true;
    } else {
      errorFlag = false;
    }
    return errorFlag;
  }
  customerSuccessValidation(result) {
    return result !== undefined && result.type === 'CUSTOMER_SUCCESS';
  }

  customerErrorValidation(result) {
    return result !== undefined && result.type === 'CUSTOMER_ERROR';
  }

  handleSubmitUpdate(e, _t) {
    if (e) {
      e.preventDefault();
    }
    let misMatchError = [];
    let state = this.state;
    let error = {};
    let successMessages = false;
    let errorFlag = false;
    this.setState({ isSubmit: true });
    if (this.state.isNotResendLink) {
      this.setState({ customerUserCreation: false });
    }
    const locationPaydetails = JSON.parse(localStorage.getItem('locationPaydetails'));
    const paymentGateway = getLocationPaymentGateway();
    this.setState({ locationId: locationPaydetails.id });
    /**check location is selected or not */
    let location = JSON.parse(localStorage.getItem('selected'));
    if (location === 0 && this.state.userDetails.permissions.allLocaton) {
      this.setState({ misMatchError: ['Please select a location'] });
      return false;
    }

    if (this.checkCardValues(this.state.card)) {
      for (const key of Object.keys(state)) {
        const { errorFlagValue, errorObj } = validateInputFields(key, state);
        if (errorFlagValue) {
          errorFlag = errorFlagValue;
          error = { ...errorObj };
          break;
        }
      }
      const zipCodeError = validateZipCode(state);
      if (zipCodeError.length > 0) {
        this.setState({ misMatchError: zipCodeError });
        return false;
      }
      const addressError = handleAddressSettingError(this.state);
      if (addressError.length > 0) {
        misMatchError = addressError;
        errorFlag = true;
      }
      if (paymentGateway === 'multipass') {
        const fieldError = this.checkTsepFieldErrors(misMatchError, error);
        if (fieldError) {
          errorFlag = true;
        }
      }
      if (errorFlag) {
        this.setState({ misMatchError, error, successMessages });
        return false;
      } else {
        if (window.confirm('Are you sure you want to update customer details?')) {
          if (paymentGateway === 'paytrace') {
            this.initiatePaytraceUpdateCustomer(misMatchError, successMessages);
          } else {
            this.initiateMultipassUpdateCustomer(misMatchError, successMessages);
          }
        }
      }
    }
  }

  setCardPayload() {
    const paymentGateway = getLocationPaymentGateway();
    let cardPayload = {
      card: { ...this.state.card, cardNumber: '', cardCsc: '' },
      phone: makePhoneNumberForDB(this.state.phone),
      encrypted_number: null,
      encrypted_csc: null,
    };
    if (paymentGateway === 'multipass') {
      const tsepResponse = this.state?.tsepSuccessResponse;
      cardPayload.encrypted_number = tsepResponse ? tsepResponse.tsepToken : null;
      cardPayload.encrypted_csc = tsepResponse ? tsepResponse.cvv2 : null;
      cardPayload.card = { ...cardPayload.card, cardExpiryDate: tsepResponse?.expirationDate };
    } else {
      cardPayload.encrypted_number = localStorage.getItem('0');
      cardPayload.encrypted_csc = localStorage.getItem('1');
    }
    return cardPayload;
  }

  sendUpdateCustomerRequest(successMessages) {
    this.props.editCustomer(this.state, this.state.csrf, this.state.paytraceCredentials).then((result) => {
      if (this.customerSuccessValidation(result)) {
        this.setState({ isLoading: false, successMessages: true }, () => {
          setTimeout(() => {
            this.setState({ redirect: true });
          }, 2000);
        });
      }
      if (this.customerErrorValidation(result)) {
        this.setState({
          isLoading: false,
          misMatchError: this.props.info.customerInfo.data.message,
          successMessages,
        });
      }
    });
  }

  initiateMultipassUpdateCustomer(successMessages) {
    const cardpayload = this.setCardPayload();
    this.setState(
      {
        isLoading: true,
        ...cardpayload,
        phone: makePhoneNumberForDB(this.state.phone),
      },
      () => {
        window.scrollTo(0, 0);
        this.sendUpdateCustomerRequest(successMessages);
      }
    );
  }

  initiatePaytraceUpdateCustomer(successMessages) {
    let cardPayload;
    if (this.state.card.cardNumber !== '_______________') {
      window.paytrace.submitEncrypted('#EditFormCustomer');
      cardPayload = this.setCardPayload();
    }
    this.setState(
      {
        isLoading: true,
        ...cardPayload,
        phone: makePhoneNumberForDB(this.state.phone),
      },
      () => {
        localStorage.removeItem('0');
        localStorage.removeItem('1');

        window.scrollTo(0, 0);
        this.sendUpdateCustomerRequest(successMessages);
      }
    );
  }
  handleChangeCheckbox(event) {
    console.log(event.target.id, 'is');
    this.setState({
      card: { ...this.state.card, [event.target.id]: event.target.checked },
    });
  }
  handleSubmitAddCard(_e) {
    this.setState({ updateCard: true, addNewCard: false });
  }

  handleSubmitAddCardTsep(_e) {
    this.setState({ updateCard: true, cardWalletID: null });
  }
  backToMaskCard(_e, _t) {
    this.setState({ addNewCard: true });
  }

  handleChangeCard(event, _t) {
    let error = {};
    let { id, value } = event.target;
    validator(id, value) ? (error[id + 'Error'] = true) : (error[id + 'Error'] = false);

    if (value[value.length - 1] === '_' && id === 'cardNumber' && value[0] === '3' && value.length === 16) {
      value = value.slice(0, -1);
    }
    this.updateStateCard(id, value, error);
    changeFocus(id, value, this.expiryDateRef.current, this.cscCardRef.current);
  }

  updateStateCard(key, value, error) {
    let misMatchError = [];
    this.setState(
      {
        card: { ...this.state.card, [key]: value },
        errorCard: { ...this.state.errorCard, [key]: false },
        error,
        misMatchError,
        isSubmit: false,
      },
      () => {
        let firstNumber = this.state.card.cardNumber.substring(0, 14);
        if (firstNumber && key == 'cardNumber') {
          this.checkCardPaymentType(firstNumber);
        }

        this.setState({ card: { ...this.state.card } });
        if (firstNumber[0] === '3') {
          this.setState({ americanExpress: true });
        } else {
          this.setState({ americanExpress: false });
        }
      }
    );
  }

  handleChangeCustom(event, i) {
    let error = {};
    const values = [...this.state.customFields];
    values[i].value = event.target.value;
    this.setState({ customFields: values, error }, () => {});
  }

  fetchAddressDetaills() {
    this.setState({ isLoading: true });
    let userData = JSON.parse(localStorage.getItem('user'));
    this.props.UserDetailById({ id: userData?._id }).then((res) => {
      if (res.type === 'USER_SUCCESS' && res.payload.userData.status === 200) {
        this.setState({
          addressSettings: {
            ...this.state.addressSettings,
            shippingAddress: res.payload.userData.data.settings?.virtualTerminalSettings?.shippingAddress,
            billingAddress: res.payload.userData.data.settings?.virtualTerminalSettings?.billingAddress,
          },
        });
      }
    });
  }

  handleFieldAddress = (event, type, isBillingAddressSameAsShippingAddress) => {
    let { id, value } = event.target;
    if (!isAddressInputValid(id, value)) {
      return;
    }

    if (type === 'shippingAddress' || isBillingAddressSameAsShippingAddress) {
      const shippingFields = {
        ...this.state.shippingAddress,
        [id]: value,
      };
      this.setState({ shippingAddress: shippingFields });
    }
    if (type === 'billingAddress') {
      const billingAddress = {
        ...this.state.billingAddress,
        [id]: value,
      };
      this.setState({ billingAddress });
    }
  };

  handleIsBillingAddressSameAsShippingAddress = (isBillingAddressSameAsShippingAddress = false) => {
    if (isBillingAddressSameAsShippingAddress) {
      this.setState({ shippingAddress: { ...this.state.billingAddress } });
    }
  };

  capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  handleChangeSaveCustomerUser(event) {
    this.setState(
      {
        customerUserCreation: true,
        isNotResendLink: false,
      },
      () => {
        this.handleSubmitUpdate();
      }
    );
  }

  shouldShowResendSignupLinkButton() {
    return this.state.customerPortal && !this.state.isActivated;
  }

  shouldShowUsernameField() {
    return this.state.isActivated;
  }

  render() {
    const { t } = this.props;
    const { redirect, ifMobile } = this.state;
    const paymentGateway = getLocationPaymentGateway();
    autoFillMultipassFields();
    return (
      <div className="rightPanel">
        {redirect ? <Redirect to="/Customers" /> : ''}
        <Topheader />
        <div className="contentPart">
          <LoadingOverlay active={this.state.isLoading} spinner text="Loading your content...">
            <Container maxWidth="xl" component="main">
              {this.state.misMatchError && this.state.misMatchError.length > 0 ? (
                <ErrorMessage errors={[this.state.misMatchError]} />
              ) : (
                ''
              )}
              {this.state.successMessages ? (
                <SuccessMessage successes={[this.props.info.customerInfo.data.message]} />
              ) : (
                ''
              )}
              {this.state.tsepFieldError ? <ErrorMessage errors={[this.state.tsepFieldError]} /> : ''}
              <form id={'EditFormCustomer'} className={classes.form} noValidate>
                <input type="hidden" name="_csrf" value={this.state.csrf['csrf-token']} />
                <input type="hidden" name="_csrfSecret" value={this.state.csrf['csrf-secret']} />
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <Textfield
                      required={true}
                      id="firstName"
                      onChange={(e) => this.handleChange(e, t)}
                      type="text"
                      labels={t('FirstName')}
                      error={this.state.error && this.state.error.firstNameError}
                      helperText={
                        this.state.error.firstNameError
                          ? rules.firstName.messages.blankspace
                          : this.state.error.firstNameError
                          ? rules.firstName.messages.stringEmptySpaces
                          : this.state.error.firstNameError
                          ? rules.firstName.messages.characterlimit
                          : ''
                      }
                      value={this.state.firstName ? this.state.firstName : ''}
                      // disabled={true}
                      width={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Textfield
                      required={true}
                      id="lastName"
                      error={this.state.error && this.state.error.lastNameError}
                      helperText={
                        this.state.error.lastNameError
                          ? rules.lastName.messages.blankspace
                          : this.state.error.lastNameError
                          ? rules.firstName.messages.stringEmptySpaces
                          : this.state.error.firstNameError
                          ? rules.lastName.messages.characterlimit
                          : ''
                      }
                      onChange={(e) => this.handleChange(e, t)}
                      type="text"
                      labels={t('LastName')}
                      value={this.state.lastName ? this.state.lastName : ''}
                      // disabled={true}
                      width={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Textfield
                      required={false}
                      error={this.state.error.emailError}
                      id="email"
                      type="email"
                      labels={t('Email')}
                      value={this.state.email}
                      // disabled={true}
                      onChange={(e) => this.handleChange(e, t)}
                      width={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Textfield
                      required={false}
                      error={this.state.error.phoneError}
                      id="phone"
                      type="tel"
                      labels={t('Phone')}
                      value={this.state.phone}
                      // disabled={true}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                      inputProps={{
                        pattern: '[0-9]*',
                        inputMode: 'numeric',
                      }}
                    />
                  </Grid>
                  <Grid container item sm={6} xs={12}>
                    <Tooltip
                      title="If you want to update Customer Id on the gateway, please add/update card information as well."
                      arrow
                    >
                      <Textfield
                        error={this.state.error.customer_idError}
                        id="customer_id"
                        helperText={this.state.error.customer_idError ? rules.customer_id.messages.blankspace : ''}
                        type="text"
                        labels={t('Customer ID')}
                        value={this.state.customer_id}
                        width={true}
                        required={false}
                        onChange={(e) => this.handleChange(e, t)}
                        focus={false}
                        disabled={paymentGateway === 'multipass'}
                      />
                    </Tooltip>
                  </Grid>
                  {this.shouldShowUsernameField() ? (
                    <>
                      <Grid item xs={12} sm={6}>
                        <Textfield
                          id="username"
                          type="text"
                          labels={t('Username')}
                          value={this.state.userName}
                          width={true}
                          required={false}
                          disabled={true}
                        />
                      </Grid>
                      <Grid container item sm={6} xs={12}></Grid>
                    </>
                  ) : (
                    <></>
                  )}
                  <Grid container item sm={6} xs={12}></Grid>
                  <Grid item xs={12} sm={6} md={6} className={'CustomerPage'}>
                    <span>
                      {this.state.creditOrDebit
                        ? this.capitalizeFirstLetter(this.state.creditOrDebit) + ' Card'
                        : 'Credit Card/Debit Card'}
                    </span>
                    {paymentGateway === 'multipass' ? (
                      <TsepCard
                        handleChangeCheckbox={this.handleChangeCheckbox}
                        tsepFieldError={this.state.tsepFieldError}
                        tsepSuccessResponse={this.state.tsepSuccessResponse}
                        customerToken={this.state.card?.customerToken}
                        customerTokenUpdate={this.state.card?.customerTokenUpdate}
                        cardWalletID={this.state?.cardWalletID}
                        maskedCardNumber={this.state.masked_card_number}
                        cardPanelCustomerShow={this.state.cardPanelCustomerShow}
                        cardPanelShow={this.state.cardPanelShow}
                        isOpen={this.state.card.isOpen}
                        updateCard={this.state.updateCard}
                        handleSubmitAddCard={this.handleSubmitAddCardTsep}
                        tsepAuth={this.props.CurrentLocation.tsepAuth}
                      />
                    ) : (
                      <>
                        <Card
                          style={{ width: '90%' }}
                          className={classNames([
                            this.state.cardPanelShow ? 'cardData' : 'blur',
                            'customCardWraper',
                            this.state.cardPanelShow && this.state.masked_card_number && this.state.addNewCard
                              ? 'none'
                              : 'block',
                          ])}
                        >
                          <div className="Card_header">
                            {!this.state.updateCard ? (
                              <Typography component="h2" variant="h5">
                                Card Details
                              </Typography>
                            ) : (
                              <>
                                <Typography component="h2" variant="h5">
                                  Card Details
                                </Typography>
                                <a className="cardBackButton" onClick={(e) => this.backToMaskCard(e, t)}>
                                  back
                                </a>
                              </>
                            )}
                          </div>
                          <CardContent className="cardContents" ref={this.cardRef}>
                            {this.state.errorCard.cardNumber ? (
                              <label
                                className={classNames([
                                  'MuiTypography-gutterBottom',
                                  this.state.errorCard.cardNumber ? 'errorCard' : '',
                                ])}
                              >
                                {t('cardNumberInvalid')}
                              </label>
                            ) : (
                              <label
                                className={classNames([
                                  'MuiTypography-gutterBottom',
                                  this.state.errorCard.cardNumber ? 'errorCard' : '',
                                ])}
                              >
                                {t('cardNumber')}
                              </label>
                            )}
                            <MaskedInput
                              type="text"
                              labels={t('cardNumber')}
                              id="cardNumber"
                              name="encrypted_number"
                              value={this.state.card.cardNumber}
                              mask={this.state.americanExpress ? '111111111111111' : '1111111111111111'}
                              className="pt-encrypt"
                              size="20"
                              onChange={(e) => this.handleChangeCard(e, t)}
                            />
                            <Grid container className="cardMeta" item xs={12}>
                              <Grid item xs={3} md={6}>
                                {this.state.errorCard.cardExpiryDate ? (
                                  <label
                                    className={classNames([
                                      'MuiTypography-gutterBottom',
                                      this.state.errorCard.cardExpiryDate ? 'errorCard' : '',
                                    ])}
                                  >
                                    {t('cardExpiryDateInvalid')}
                                  </label>
                                ) : (
                                  <label
                                    className={classNames([
                                      'MuiTypography-gutterBottom',
                                      this.state.errorCard.cardExpiryDate ? 'errorCard' : '',
                                    ])}
                                  >
                                    {t('cardExpiryDate')}
                                  </label>
                                )}
                                <MaskedInput
                                  id="cardExpiryDate"
                                  name="cardExpiryDate"
                                  placeholder="mm/yyyy"
                                  mask="11/1111"
                                  value={this.state.card.cardExpiryDate}
                                  ref={this.expiryDateRef}
                                  onChange={(e) => this.handleChangeCard(e, t)}
                                />
                              </Grid>
                              <Grid item xs={3} md={6}>
                                {this.state.errorCard.cardCsc ? (
                                  <label
                                    className={classNames([
                                      'MuiTypography-gutterBottom',
                                      this.state.errorCard.cardCsc ? 'errorCard' : '',
                                    ])}
                                  >
                                    {t('cardCscInvalid')}
                                  </label>
                                ) : (
                                  <label
                                    className={classNames([
                                      'MuiTypography-gutterBottom',
                                      this.state.errorCard.cardCsc ? 'errorCard' : '',
                                    ])}
                                  >
                                    {t('cardCsc')}
                                  </label>
                                )}
                                <MaskedInput
                                  id="cardCsc"
                                  value={this.state.card.cardCsc}
                                  name="encrypted_csc"
                                  mask={this.state.americanExpress ? '1111' : '111'}
                                  type="text"
                                  className="pt-encrypt"
                                  ref={this.cscCardRef}
                                  onChange={(e) => this.handleChangeCard(e, t)}
                                />
                              </Grid>
                            </Grid>
                          </CardContent>
                          <CardActions>
                            {this.state.updateCard ? (
                              <>
                                {' '}
                                <Checkbox
                                  id="customerToken"
                                  color="primary"
                                  checked={this.state.card.customerToken}
                                  onChange={(e) => {
                                    this.handleChangeCheckbox(e);
                                  }}
                                  inputProps={{
                                    'aria-label': 'secondary checkbox',
                                  }}
                                  value={this.state.card.customerToken}
                                />
                                <label>Save card for future payments</label>
                              </>
                            ) : (
                              <>
                                {' '}
                                <Checkbox
                                  checked={this.state.card.customerTokenUpdate}
                                  onChange={(e) => {
                                    this.handleChangeCheckboxUpdate(e);
                                  }}
                                  color="primary"
                                  value={this.state.card.customerTokenUpdate}
                                  id="customerTokenUpdate"
                                  inputProps={{
                                    'aria-label': 'secondary checkbox',
                                  }}
                                />
                                <label>Update card for future payments</label>
                              </>
                            )}
                          </CardActions>
                        </Card>
                        <div
                          style={{ width: '90%' }}
                          sm={6}
                          className={this.state.addNewCard && this.state.masked_card_number ? 'block' : 'none'}
                        >
                          <Card
                            className={classNames([this.state.cardPanelShow ? 'maskData' : 'blur', 'customCardWraper'])}
                          >
                            <div className="Card_header">
                              <Typography variant="h5" component="h2">
                                Name : {this.state.firstName + ' ' + this.state.lastName}
                              </Typography>
                            </div>
                            <CardContent ref={this.cardRef} className="cardContents">
                              <labels className="MuiTypography-gutterBottom">{t('maskCardNumber')}</labels>
                              <Grid container item xs={12}>
                                {this.state.masked_card_number ? this.state.masked_card_number : ''}
                              </Grid>
                            </CardContent>
                            <CardActions>
                              <Buttons
                                id="AddNewCard"
                                type="button"
                                variant="contained"
                                color="secondary"
                                className={'addNewCardBtn'}
                                onClick={(e) => this.handleSubmitAddCard(e)}
                                text={t('addAnotherCard')}
                              />
                            </CardActions>
                          </Card>
                        </div>
                      </>
                    )}
                  </Grid>

                  <Grid container spacing={3} direction="row">
                    {checkAddressEnabled(this.state.addressSettings?.billingAddress) && (
                      <Grid item md={12} sm={6}>
                        <AddressFieldsDropDown
                          align={ifMobile ? 'column' : 'row'}
                          type="billing"
                          state={this.state}
                          handleChange={this.handleFieldAddress}
                          handleSameAddress={this.handleIsBillingAddressSameAsShippingAddress}
                        />
                      </Grid>
                    )}
                    {checkAddressEnabled(this.state.addressSettings?.shippingAddress) && (
                      <Grid item md={12} sm={6}>
                        <AddressFieldsDropDown
                          align={ifMobile ? 'column' : 'row'}
                          type="shipping"
                          state={this.state}
                          handleChange={this.handleFieldAddress}
                          handleSameAddress={this.handleIsBillingAddressSameAsShippingAddress}
                        />
                      </Grid>
                    )}
                  </Grid>
                  {this.state?.customFields?.map((field, idx) => {
                    return (
                      // eslint-disable-next-line react/jsx-key
                      <Grid item sm={6} xs={6}>
                        <div key={`${field}-${idx}`}>
                          <Textfield
                            id={field.label}
                            type="text"
                            labels={field.label}
                            value={field.value ? field.value : ''}
                            width={true}
                            onChange={(e) => this.handleChangeCustom(e, idx)}
                            focus={true}
                          />
                        </div>
                      </Grid>
                    );
                  })}
                  <Grid item xs={12} sm={12}>
                    {this.props.user.permissions.editCustomer ? (
                      <Buttons
                        id="saveCustomer"
                        variant="contained"
                        className={classes.ml_2}
                        color="primary"
                        text={t('Update')}
                        onClick={(e) => this.handleSubmitUpdate(e, t)}
                      />
                    ) : (
                      ''
                    )}
                    {this.shouldShowResendSignupLinkButton() ? (
                      <Buttons
                        id="customerSignupLink"
                        variant="contained"
                        className={classes.ml_2}
                        color="info"
                        text={'Resend Signup Link'}
                        onClick={(e) => this.handleChangeSaveCustomerUser(e)}
                      />
                    ) : (
                      ''
                    )}
                    <Buttons
                      className={classes.ml_2}
                      variant="contained"
                      color="secondary"
                      text={t('Back')}
                      onClick={this.props.history.goBack}
                    />
                  </Grid>
                </Grid>
              </form>
            </Container>
          </LoadingOverlay>
        </div>
      </div>
    );
  }
}

EditCustomers.propTypes = {
  PAYTRACE_AWS_PATH: PropTypes.any,
  user: PropTypes.any,
  info: PropTypes.any,
  t: PropTypes.any,
  getCsrfToken: PropTypes.func,
  editCustomer: PropTypes.func,
  CustomerDetailsId: PropTypes.func,
  history: PropTypes.any,
  match: PropTypes.any,
  UserDetailById: PropTypes.func,
  CurrentLocation: PropTypes.any,
  setTsepAuth: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    info: state.CustomerReducer,
    user: state.AdminUserReducer,
    PAYTRACE_AWS_PATH: state.EnvironmentVariablesReducer.data.PAYTRACE_AWS_PATH,
    CurrentLocation: state.LocationReducer,
  };
}

export default connect(mapStateToProps, { UserDetailById, editCustomer, getCsrfToken, CustomerDetailsId, setTsepAuth })(
  withTranslation()(EditCustomers)
);
