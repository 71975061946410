import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import classes from './User.module.scss';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Textfield } from './../../components/Textfield';
import { Buttons } from './../../components/Buttons';
import { Topheader } from './../../components/Topheader';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import { UserDetailById, adminUserProfile } from '../../actions/AdminUserAction';
import LoadingOverlay from 'react-loading-overlay';
import { config } from '../../constants';
import validator from '../../helpers/checkValidations';
import Resizer from 'react-image-file-resizer';
import { omit } from 'lodash';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  thumb: {
    width: 24,
    height: 24,
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },

  checked: {},
  focusVisible: {},

  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      param: {
        userName: '',
        firstName: '',
        lastName: '',
        email: '',
        image: null,
        selectedFile: null,
        declineEmailNotification: false,
      },
      isEmailNotification: false,
      imageUrl: null,
      loaded: false,
      sideShow: true,
      isLoading: true,
      misMatchError: [],
      logoError: [],
      userData: JSON.parse(localStorage.getItem('user')),
      response: { success: '', message: '' },
      error: {
        userNameError: false,
        firstNameError: false,
        lastNameError: false,
        emailError: false,
        fileError: false,
      },
    };
  }

  componentDidMount() {
    this.getUserData();
  }

  getUserData() {
    const {
      match: { params },
    } = this.props;
    this.setState({ isLoading: true });
    // const currentLocation = this.props?.LocationCurrent?.currentLocation;
    const currentLocation = this.state.userData;
    if (currentLocation?.image === null || currentLocation?.image === undefined) {
      this.setState({ imageUrl: null });
    } else {
      const uploadImageUrl = this.props.PAYTRACE_AWS_PATH + currentLocation?.image;
      this.setState({ imageUrl: uploadImageUrl });
    }
    this.props.UserDetailById(params).then((res) => {
      this.setState({
        param: {
          ...res.payload.userData.data,
          declineEmailNotification: res.payload.userData.data.permissions?.declineEmailNotification,
        },
        isLoading: false,
      });
    });
  }
  handleClickMenu(_e) {
    if (this.state.sideShow) this.setState({ sideShow: false });
    else this.setState({ sideShow: true });
  }
  handleClickRefresh() {
    this.getUserData();
  }
  handleChangeCheckbox(event) {
    this.setState({ [event.target.id]: event.target.checked });
  }
  handleChange(event, _t) {
    let error = {};
    let misMatchError = [];
    let { id, value } = event.target;
    validator(id, value) ? (error[id + 'Error'] = true) : (error[id + 'Error'] = false);
    this.setState({ param: { ...this.state.param, [id]: value }, error, misMatchError });
  }

  handleChangeID(e, name) {
    let successMessages = false;
    let successStr = '';
    this.setState({ param: { ...this.state.param, [name]: e.target.checked }, successStr, successMessages });
  }

  handleSubmit(e, t) {
    let misMatchError = [];
    let logoError = [];
    let successMessages = false;
    let state = this.state;
    let error = {};
    let errorFlag = false;
    Object.keys(omit(this.state.param, ['password'])).map((key, _value) => {
      if (validator(key, this.state.param[key])) {
        return validator(key, this.state.param[key])
          ? ((error[key + 'Error'] = true), (errorFlag = true))
          : (error[key + 'Error'] = false);
      } else {
        return this.state.param[key] === '' ||
          (typeof this.state.param[key] === 'string' && this.state.param[key].trim() === '')
          ? ((error[key + 'Error'] = true), (errorFlag = true))
          : '';
      }
    });

    if (state.error.fileError) {
      logoError[0] = t('UserLogo');
      this.setState({ logoError });
      return false;
    }
    if (errorFlag) {
      this.setState({ misMatchError, error, successMessages });
      return false;
    } else {
      const data = new FormData();
      for (const key in this.state.param) {
        data.append(key, this.state.param[key]);
      }
      this.setState({ isLoading: true });
      this.props.adminUserProfile(data).then((result) => {
        window.scrollTo(0, 0, 0);
        if (result !== undefined && result.type === 'USER_SUCCESS') {
          if (
            result.payload.userData.data.response.response.image === undefined ||
            result.payload.userData.data.response.response.image == null
          ) {
            this.setState({ successMessages: true, isLoading: false });
          } else {
            const uploadImageUrl = this.props.PAYTRACE_AWS_PATH + result.payload.userData.data.response.response.image;
            this.setState({
              param: { ...this.state.param, selectedFile: null },
              isLoading: false,
              successMessages: true,
              imageUrl: uploadImageUrl,
              loaded: false,
            });
          }
          this.setState({ response: { ...this.state.response, message: result.payload.userData.data.message } }, () => {
            setTimeout(() => {
              this.setState({ successMessages: false });
            }, 2000);
          });
        }
        if (result !== undefined && result.type === 'USER_ERROR') {
          this.setState({ misMatchError: this.props.info.userInfo.data.message, isLoading: false, successMessages });
        }
      });
    }
  }

  //upload image
  onChangeHandler = (event, t) => {
    let successMessages = false;
    let logoError = [];
    let error = false;
    let selectedFile = event.target.files[0] ? URL.createObjectURL(event.target.files[0]) : '';
    let image = event.target.files[0];
    const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;

    if (!allowedExtensions.exec(image.name)) {
      logoError.push(t('UserFileTypeNotCorrect'));
      error = true;
      this.setState({
        error: { ...this.state.error, fileError: true },
        logoError,
        image: null,
        loaded: false,
        successMessages,
      });
    }
    const size = 5 * 1048576;

    if (image.size > size) {
      logoError.push(t('UserFileSizeNotCorrect'));
      this.setState({ error: { ...this.state.error, fileError: true }, logoError, image: null, successMessages });
    }

    if (!error) {
      Resizer.imageFileResizer(
        event.target.files[0],
        150,
        150,
        'JPEG',
        100,
        0,
        (uri) => {
          let file = new File([uri], image.name);
          this.setState({
            param: { ...this.state.param, image: file, selectedFile: selectedFile },
            loaded: true,
            successMessages,
          });
        },
        'blob'
      );
    }
  };

  render() {
    const { t } = this.props;
    let user = JSON.parse(localStorage.getItem('user'));

    return (
      <div className="rightPanel">
        <Topheader />
        <div className="contentPart">
          <LoadingOverlay active={this.state.isLoading} spinner text="Loading your content...">
            <Container component="main" maxWidth="xl">
              {this.state.misMatchError.length > 0 ? <ErrorMessage errors={[this.state.misMatchError]} /> : ''}
              {this.state.successMessages ? <SuccessMessage successes={[this.state.response.message]} /> : ''}
              <form className={classes.form} noValidate>
                <Grid container spacing={3}>
                  <Grid item xs={6} sm={6}>
                    <Textfield
                      required={true}
                      error={this.state.error.userNameError}
                      id="userName"
                      type="text"
                      labels={t('Username')}
                      value={this.state.param.userName}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                      focus={true}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Textfield
                      required={true}
                      error={this.state.error.firstNameError}
                      id="firstName"
                      type="text"
                      labels={t('FirstName')}
                      value={this.state.param.firstName}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                    />
                  </Grid>
                  {/* <Grid item xs={6} sm={3}>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                  </Grid> */}
                  <Grid item xs={6} sm={6}>
                    <Textfield
                      required={true}
                      error={this.state.error.lastNameError}
                      id="lastName"
                      type="text"
                      labels={t('LastName')}
                      value={this.state.param.lastName}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Textfield
                      required={true}
                      error={this.state.error.emailError}
                      id="email"
                      type="email"
                      labels={t('Email')}
                      value={this.state.param.email}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                    />
                  </Grid>
                  {/* <Grid item xs={6} sm={3}>
                    <label><b></b></label>
                  </Grid> */}
                  <Grid item xs={6} sm={6}>
                    {this.state.imageUrl !== null ? (
                      <img alt="profile" src={this.state.imageUrl} className={classes.preview} />
                    ) : (
                      <img className={classes.preview} alt="blank" src={config.APPURL + '/assets/images/blank.png'} />
                    )}
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <div className={this.state.loaded ? classes.show : classes.hide}>
                      {this.state.param.selectedFile ? (
                        <img alt="preview" className={classes.preview} src={this.state.param.selectedFile} />
                      ) : (
                        <img className={classes.preview} alt="blank" src={config.APPURL + '/assets/images/blank.png'} />
                      )}
                    </div>
                    <span className={this.state.loaded ? classes.show : classes.hide}>Preview</span>
                  </Grid>

                  <Grid item xs={6} sm={6}>
                    <input
                      type="file"
                      name="file"
                      placeholder="Add profile picture"
                      onChange={(e) => {
                        this.onChangeHandler(e, t);
                      }}
                    />
                    {this.state.logoError.length > 0 ? <ErrorMessage errors={[this.state.logoError]} /> : ''}
                  </Grid>
                </Grid>
                {this.state.userData.role > 1 && this.props.info.permissions ? <h2>Permissions</h2> : ''}
                <Grid container item xs={12}>
                  {this.props.info.permissions.refunds ? (
                    <Grid item xs={6} sm={6}>
                      <label>
                        <span>Refunds</span>
                      </label>
                      <img
                        alt="enable"
                        className={classes.rightImg}
                        src={config.APPURL + '/assets/images/checkmark-24.png'}
                      />
                    </Grid>
                  ) : (
                    ''
                  )}

                  {this.props.info.permissions.editCustomer ? (
                    <Grid item xs={6} sm={6}>
                      <label>
                        <span>Edit Customer</span>
                      </label>
                      <img
                        alt="enable"
                        className={classes.rightImg}
                        src={config.APPURL + '/assets/images/checkmark-24.png'}
                      />
                    </Grid>
                  ) : (
                    ''
                  )}

                  {this.props.info.permissions.textToInvoice ? (
                    <Grid item xs={6} sm={6}>
                      <label>
                        <span>Texting</span>
                      </label>
                      <img
                        alt="enable"
                        className={classes.rightImg}
                        src={config.APPURL + '/assets/images/checkmark-24.png'}
                      />
                    </Grid>
                  ) : (
                    ''
                  )}

                  {this.props.info.permissions.textInvoice ? (
                    <Grid item xs={6} sm={6}>
                      <label>
                        <span>Text Invoice</span>
                      </label>
                      <img
                        alt="enable"
                        className={classes.rightImg}
                        src={config.APPURL + '/assets/images/checkmark-24.png'}
                      />
                    </Grid>
                  ) : (
                    ''
                  )}

                  {this.props.info.permissions && this.props.info.permissions.textNotifications ? (
                    <Grid item xs={6} sm={6}>
                      <label>
                        <span>Text Notifications</span>
                      </label>
                      <img
                        alt="enable"
                        className={classes.rightImg}
                        src={config.APPURL + '/assets/images/checkmark-24.png'}
                      />
                    </Grid>
                  ) : (
                    ''
                  )}

                  {this.props.info.permissions.createInvoice ? (
                    <Grid item xs={12} sm={6}>
                      <label>
                        <span>Invoicing</span>
                      </label>
                      <img
                        alt="enable"
                        className={classes.rightImg}
                        src={config.APPURL + '/assets/images/checkmark-24.png'}
                      />
                    </Grid>
                  ) : (
                    ''
                  )}

                  {user?.permissions?.ach && user?.company?.ACH ? (
                    <Grid item xs={12} sm={6}>
                      <label>
                        <span>ACH</span>
                      </label>
                      <img
                        alt="enable"
                        className={classes.rightImg}
                        src={config.APPURL + '/assets/images/checkmark-24.png'}
                      />
                    </Grid>
                  ) : (
                    ''
                  )}

                  {/* <Grid item xs={12} sm={6}></Grid> */}

                  {this.props.info.permissions.virtualTerminal ? (
                    <Grid item xs={12} sm={6}>
                      <label>
                        <span>Virtual Terminal</span>
                      </label>
                      <img
                        alt="enable"
                        className={classes.rightImg}
                        src={config.APPURL + '/assets/images/checkmark-24.png'}
                      />
                    </Grid>
                  ) : (
                    ''
                  )}

                  {this.props.info.permissions.recurring ? (
                    <Grid item xs={12} sm={6}>
                      <label>
                        <span>Recurring Billing</span>
                      </label>
                      <img
                        alt="enable"
                        className={classes.rightImg}
                        src={config.APPURL + '/assets/images/checkmark-24.png'}
                      />
                    </Grid>
                  ) : (
                    ''
                  )}

                  {this.props.info.permissions.allLocaton ? (
                    <Grid item xs={12} sm={6}>
                      <label>
                        <span>All Locaton</span>
                      </label>
                      <img
                        alt="enable"
                        className={classes.rightImg}
                        src={config.APPURL + '/assets/images/checkmark-24.png'}
                      />
                    </Grid>
                  ) : (
                    ''
                  )}

                  {this.props.info.permissions.feeEdit ? (
                    <Grid item xs={12} sm={6}>
                      <label>
                        <span>Fee Edit</span>
                      </label>
                      <img
                        alt="enable"
                        className={classes.rightImg}
                        src={config.APPURL + '/assets/images/checkmark-24.png'}
                      />
                    </Grid>
                  ) : (
                    ''
                  )}

                  {this.props.info.permissions.creatNewUser ? (
                    <Grid item xs={12} sm={6}>
                      <label>
                        <span>Create New User</span>
                      </label>
                      <img
                        alt="enable"
                        className={classes.rightImg}
                        src={config.APPURL + '/assets/images/checkmark-24.png'}
                      />
                    </Grid>
                  ) : (
                    ''
                  )}

                  {this.props.info.permissions.exportRecord ? (
                    <Grid item xs={12} sm={6}>
                      <label>
                        <span>Export Record</span>
                      </label>
                      <img
                        alt="enable"
                        className={classes.rightImg}
                        src={config.APPURL + '/assets/images/checkmark-24.png'}
                      />
                    </Grid>
                  ) : (
                    ''
                  )}

                  {this.props.info.permissions.refundsManual ? (
                    <Grid item xs={12} sm={6}>
                      <label>
                        <span>Manual Refunds</span>
                      </label>
                      <img
                        alt="enable"
                        className={classes.rightImg}
                        src={config.APPURL + '/assets/images/checkmark-24.png'}
                      />
                    </Grid>
                  ) : (
                    ''
                  )}

                  {this.props.info.permissions.void ? (
                    <Grid item xs={12} sm={6}>
                      <label>
                        <span>Void</span>
                      </label>
                      <img
                        alt="enable"
                        className={classes.rightImg}
                        src={config.APPURL + '/assets/images/checkmark-24.png'}
                      />
                    </Grid>
                  ) : (
                    ''
                  )}

                  {this.props.info.permissions.displayProducts ? (
                    <Grid item xs={12} sm={6}>
                      <label>
                        <span>Display Products</span>
                      </label>
                      <img
                        alt="enable"
                        className={classes.rightImg}
                        src={config.APPURL + '/assets/images/checkmark-24.png'}
                      />
                    </Grid>
                  ) : (
                    ''
                  )}

                  {this.props.info.permissions.displayDashboard ? (
                    <Grid item xs={12} sm={6}>
                      <label>
                        <span>Display Dashboard</span>
                      </label>
                      <img
                        alt="enable"
                        className={classes.rightImg}
                        src={config.APPURL + '/assets/images/checkmark-24.png'}
                      />
                    </Grid>
                  ) : (
                    ''
                  )}

                  {this.props.info.permissions.displayTickets ? (
                    <Grid item xs={12} sm={6}>
                      <label>
                        <span>Display Tickets</span>
                      </label>
                      <img
                        alt="enable"
                        className={classes.rightImg}
                        src={config.APPURL + '/assets/images/checkmark-24.png'}
                      />
                    </Grid>
                  ) : (
                    ''
                  )}

                  {this.props.info.permissions.displayLocations ? (
                    <Grid item xs={12} sm={6}>
                      <label>
                        <span>Display Locations</span>
                      </label>
                      <img
                        alt="enable"
                        className={classes.rightImg}
                        src={config.APPURL + '/assets/images/checkmark-24.png'}
                      />
                    </Grid>
                  ) : (
                    ''
                  )}

                  {this.props.info.permissions.displayTransactions ? (
                    <Grid item xs={12} sm={6}>
                      <label>
                        <span>Display Transactions</span>
                      </label>
                      <img
                        alt="enable"
                        className={classes.rightImg}
                        src={config.APPURL + '/assets/images/checkmark-24.png'}
                      />
                    </Grid>
                  ) : (
                    ''
                  )}

                  {this.props.info.permissions.displaySettings && this.state.userData.role === 2 ? (
                    <Grid item xs={12} sm={6}>
                      <label>
                        <span>Display Settings</span>
                      </label>
                      <img
                        alt="enable"
                        className={classes.rightImg}
                        src={config.APPURL + '/assets/images/checkmark-24.png'}
                      />
                    </Grid>
                  ) : (
                    ''
                  )}

                  {this.state.userData.role === 2 || this.state.userData.role === 3 ? (
                    <>
                      {this.props.info.permissions.displayUsers ? (
                        <Grid item xs={12} sm={6}>
                          <label>
                            <span>Display Users</span>
                          </label>
                          <img
                            alt="enable"
                            className={classes.rightImg}
                            src={config.APPURL + '/assets/images/checkmark-24.png'}
                          />
                        </Grid>
                      ) : (
                        ''
                      )}
                    </>
                  ) : (
                    ''
                  )}

                  {this.props.info.permissions.emailNotification ? (
                    <Grid item xs={12} sm={6}>
                      <label>
                        <span>Get Email Notifications For Paid Invoices</span>
                      </label>
                      <img
                        alt="enable"
                        className={classes.rightImg}
                        src={config.APPURL + '/assets/images/checkmark-24.png'}
                      />
                    </Grid>
                  ) : (
                    ''
                  )}

                  {this.props.info.permissions.deleteInvoice ? (
                    <Grid item xs={12} sm={6}>
                      <label>
                        <span>Delete Invoice</span>
                      </label>
                      <img
                        alt="enable"
                        className={classes.rightImg}
                        src={config.APPURL + '/assets/images/checkmark-24.png'}
                      />
                    </Grid>
                  ) : (
                    ''
                  )}

                  {this.state.param.declineEmailNotification ? (
                    <Grid item xs={12} sm={6}>
                      <label>
                        <span>{t('declineEmailNotifications')}</span>
                      </label>
                      <img
                        alt="enable"
                        className={classes.rightImg}
                        src={config.APPURL + '/assets/images/checkmark-24.png'}
                      />
                    </Grid>
                  ) : (
                    ''
                  )}
                </Grid>

                {this.props.info.permissions.viewAllTransactions ? (
                  <Grid item xs={12} sm={6}>
                    <label>
                      <span>View All Transactions</span>
                    </label>
                    <img
                      alt="enable"
                      className={classes.rightImg}
                      src={config.APPURL + '/assets/images/checkmark-24.png'}
                    />
                  </Grid>
                ) : (
                  ''
                )}

                {user.permissions && user.permissions.emailVisibilityTerminal ? (
                  <Grid item xs={10} sm={4}>
                    <>
                      <label>
                        <span>Terminal Email</span>
                      </label>
                      <img
                        alt="enable"
                        className={classes.rightImg}
                        src={config.APPURL + '/assets/images/checkmark-24.png'}
                      />
                    </>
                  </Grid>
                ) : (
                  ''
                )}
                {user.permissions && user.permissions.notesVisibilityTerminal ? (
                  <Grid item xs={10} sm={4}>
                    <>
                      <label>
                        <span>Terminal Notes</span>
                      </label>
                      <img
                        alt="enable"
                        className={classes.rightImg}
                        src={config.APPURL + '/assets/images/checkmark-24.png'}
                      />
                    </>
                  </Grid>
                ) : (
                  ''
                )}
                <Grid container item xs={12}>
                  <Grid item xs={12} sm={12}>
                    <div>&nbsp;</div>

                    <Grid item xs={12} sm={6} md={12} className="vt-settings-container">
                      <div className="vt-settings-inner">
                        <Grid container spacing={3}>
                          {user.permissions && user.permissions.emailNotification ? (
                            <Grid item xs={6} sm={6} className="discount-label-field">
                              <label className="mr-2">Get Email Notifications For Paid Invoices</label>
                              <FormControlLabel
                                control={
                                  <IOSSwitch
                                    checked={
                                      this.state.param.isEmailNotification
                                        ? this.state.param.isEmailNotification
                                        : false
                                    }
                                    onChange={(e) => {
                                      this.handleChangeID(e, 'isEmailNotification');
                                    }}
                                    value={
                                      this.state.param.isEmailNotification
                                        ? this.state.param.isEmailNotification
                                        : false
                                    }
                                  />
                                }
                              />
                            </Grid>
                          ) : (
                            ''
                          )}
                          <Grid item xs={6} sm={6} className="discount-label-field">
                            <label className="mr-2">{t('declineEmailNotifications')}</label>
                            <FormControlLabel
                              control={
                                <IOSSwitch
                                  checked={this.state.param.declineEmailNotification || false}
                                  onChange={(e) => {
                                    this.handleChangeID(e, 'declineEmailNotification');
                                  }}
                                  value={this.state.param.declineEmailNotification || false}
                                />
                              }
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Buttons
                      id="signup"
                      type="button"
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={(e) => this.handleSubmit(e, t)}
                      text={t('Update')}
                    />
                    <Buttons
                      type="button"
                      className={classes.ml_2}
                      variant="contained"
                      color="secondary"
                      text={t('Back')}
                      onClick={this.props.history.goBack}
                    />
                  </Grid>
                </Grid>
              </form>
            </Container>
          </LoadingOverlay>
        </div>
      </div>
    );
  }
}
Profile.propTypes = {
  t: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    info: state.AdminUserReducer,
    PAYTRACE_AWS_PATH: state.EnvironmentVariablesReducer.data.PAYTRACE_AWS_PATH,
    LocationCurrent: state.LocationReducer,
  };
}

Profile.propTypes = {
  adminUserProfile: PropTypes.func,
  PAYTRACE_AWS_PATH: PropTypes.string,
  info: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  UserDetailById: PropTypes.func,
  LocationCurrent: PropTypes.any,
};

export default connect(mapStateToProps, { UserDetailById, adminUserProfile })(withTranslation()(Profile));
