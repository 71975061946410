import { formatCurrency } from './commonFunction';

const PrintInvoice = (data) => {
  const date = new Date(data.invoiceDate).toISOString();
  const formattedDate = date ? date.toLocaleString() : 'N/A';
  let my_window = window.open('mywindow', 'status=1,width=650,height=450');
  my_window.document.write(
    `
    <!DOCTYPE html>
    <html>
      <head>
        <title>
        Print Invoice
        </title>
      </head>`
  );
  my_window.document.write(`<body  onafterprint="self.close()">`);
  data.name &&
    my_window.document.write(
      `<p style="padding: 0; margin: 0; box-sizing: border-box; font-family: sans-serif; font-size: 18px;">Hi <strong style="padding: 0; margin: 0; box-sizing: border-box; font-family: sans-serif; font-size: 18px;">${
        data.name.split(' ')[0].charAt(0).toUpperCase() +
        data.name.split(' ')[0].slice(1) +
        ' ' +
        data.name.split(' ')[1].charAt(0).toUpperCase() +
        data.name.split(' ')[1].slice(1)
      }</strong>,</p>`
    );

  my_window.document.write(
    `<h3 style="padding: 0; box-sizing: border-box; font-family: sans-serif; margin: 25px 0; text-align: center; font-size: 25px; font-weight: 700; color: rgba(53, 94, 170, 1);">INVOICE</h3>`
  );
  my_window.document
    .write(`<table style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; width: 100%; border-collapse: collapse;" width="100%">
       
    ${
      data && (data.jobId || data.invoice)
        ? ` <tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
               <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family:sans-serif;"> ${
                 data.customInvoiceName
               }</strong></td>
               <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: right; padding: 8px 0;">${
                 data.jobId ?? data.invoice
               }</td>
            </td>
           </tr>`
        : ``
    }

    ${
      data && data.name
        ? ` <tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
               <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family:sans-serif;">Customer Name </strong></td>
               <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: right; padding: 8px 0;">${
                 data.name.split(' ')[0].charAt(0).toUpperCase() +
                 data.name.split(' ')[0].slice(1) +
                 ' ' +
                 data.name.split(' ')[1].charAt(0).toUpperCase() +
                 data.name.split(' ')[1].slice(1)
               }</td>
            </td>
           </tr>`
        : ``
    }
   ${
     data.transactionId
       ? `<tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
            <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">Transaction Id</strong></td>
            <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: right; padding: 8px 0;">${data.transactionId}</td>
           </tr>`
       : ``
   }
    ${
      formattedDate
        ? `<tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
            <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">Invoice Date</strong></td>
            <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: right; padding: 8px 0;">${formattedDate}</td>
           </tr>`
        : ``
    }

        ${
          data.isCardTypeAvailable
            ? ` <tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
               <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family:sans-serif;">Card Type </strong></td>
               <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: right; padding: 8px 0;">${data.cardType}</td>
            </td>
           </tr>`
            : ``
        }

  ${
    data.isCardNumberAvailable
      ? ` <tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
               <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family:sans-serif;">Card Number </strong></td>
               <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: right; padding: 8px 0;">${data.maskedCardNumber}</td>
            </td>
           </tr>`
      : ``
  }


    ${
      data?.paymentType
        ? `<tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
              <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">Payment Type</strong></td>
              <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: right; padding: 8px 0;">${data?.paymentType}</td>
             </tr>`
        : ``
    }
  ${
    data.total
      ? `<tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
      <td style="ma && data.svcFee && data.taxrgin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">Subtotal</strong></td>
      <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: right; padding: 8px 0;">${formatCurrency(
        parseFloat(data.total) -
          (parseFloat(data.svcFee || 0) +
            parseFloat(data.tax || 0) +
            parseFloat(data.isLateFeeApplicable && data.lateFee ? data.lateFee : 0) +
            parseFloat(data.surcharge || 0))
      )}</td>
  </tr>`
      : ``
  }


  ${
    data.isSurchargeApplied
      ? `<tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
      <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">${
        data.surchargeFieldName || 'Surcharge Fee'
      }</strong></td>
      <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: right; padding: 8px 0;">${formatCurrency(
        data.surcharge
      )}</td>
  </tr>`
      : ``
  }

  ${
    data.isLateFeeApplicable && data.lateFee
      ? `<tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
      <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">${
        data.feeSettings?.invoiceSettings?.CustomFeeOneName || 'Late Fee'
      }</strong></td>
      <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: right; padding: 8px 0;">${formatCurrency(
        parseFloat(data.lateFee)
      )}</td>
  </tr>`
      : ``
  }

    ${
      data.svcFee && data.svcFee > 0 && !data.feeSettings.DualFeeEnabled
        ? `<tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
      <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">${
        data.discountFeeName || 'SVC Fee'
      }</strong></td>
      <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: right; padding: 8px 0;">${formatCurrency(
        data.svcFee
      )}</td>
    </tr>`
        : ``
    }
        
    
    ${
      data.tax
        ? `<tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
        <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">Sales tax amount</strong></td>
        <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: right; padding: 8px 0;">${formatCurrency(
          data.tax
        )}</td>
    </tr>`
        : ``
    }
</table>


${
  data.Products && data.Products.length > 0
    ? `
<h3>Products</h3>
<table style="padding: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; margin: 30px 0 30px; width: 100%; border-collapse: collapse; border-top: 2px solid rgba(0, 0, 0, 0.87); border-bottom: 2px solid rgba(0, 0, 0, 0.87);" width="100%">
<tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
        <th style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">Name</strong></th>
        <th style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">Quantity</strong></th>
        <th style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">Description</strong></th>
        <th style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">Amount</strong></th>
</tr>
${data.Products.map((product) => {
  return `<tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
        <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">${
          product.name
        }</strong></td>
        <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">${
          product.quantity
        }</strong></td>
        <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">${
          product.description
        }</strong></td>
        <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">${formatCurrency(
          product.unitCost
        )}</strong></td>
      </tr>`;
})}
</table>`
    : ''
}

${
  data.feeSettings && !data.feeSettings?.DualFeeEnabled
    ? `<table style="padding: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; margin: 30px 0 30px; width: 100%; border-collapse: collapse; border-top: 2px solid rgba(0, 0, 0, 0.87); border-bottom: 2px solid rgba(0, 0, 0, 0.87);" width="100%">
      <tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
          <td style="margin: 0; box-sizing: border-box; font-family: sans-serif; text-align: left; padding: 10px 0; font-size: 16px; font-weight: bold;">Total amount</td>
          <td style="margin: 0; box-sizing: border-box; font-family: sans-serif; text-align: right; padding: 10px 0; font-size: 16px; font-weight: bold;">${formatCurrency(
            data.total || data.amount
          )}</td>
        </td>
      </tr> 
    </table>`
    : ``
}

${
  data.feeSettings && data.feeSettings?.DualFeeEnabled
    ? `<table style="padding: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; margin: 30px 0 30px; width: 100%; border-collapse: collapse; border-top: 2px solid rgba(0, 0, 0, 0.87); border-bottom: 2px solid rgba(0, 0, 0, 0.87);" width="100%">
      <tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
          <td style="margin: 0; box-sizing: border-box; font-family: sans-serif; text-align: left; padding: 10px 0; font-size: 16px; font-weight: bold;">Cash Total</td>
          <td style="margin: 0; box-sizing: border-box; font-family: sans-serif; text-align: right; padding: 10px 0; font-size: 16px; font-weight: bold;">${formatCurrency(
            data.cashTotal
          )}</td>
        </td>
      </tr> 
      <tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
          <td style="margin: 0; box-sizing: border-box; font-family: sans-serif; text-align: left; padding: 10px 0; font-size: 16px; font-weight: bold;">Card Total</td>
          <td style="margin: 0; box-sizing: border-box; font-family: sans-serif; text-align: right; padding: 10px 0; font-size: 16px; font-weight: bold;">${formatCurrency(
            data.total
          )}</td>
        </td>
      </tr> 
    </table>`
    : ``
}


 <table style="padding: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; margin: 30px 0 30px; width: 100%; border-collapse: collapse; border-top: 2px solid rgba(0, 0, 0, 0.87); border-bottom: 2px solid rgba(0, 0, 0, 0.87);" width="100%">
 ${
   data.STATUS
     ? `<tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
      <td style="margin: 0; box-sizing: border-box; font-family: sans-serif; text-align: left; padding: 10px 0; font-size: 16px; font-weight: bold;">Payment Status</td>
      <td style="margin: 0; box-sizing: border-box; font-family: sans-serif; text-align: right; padding: 10px 0; font-size: 16px; font-weight: bold;">${data.STATUS}</td>
      
  </tr>`
     : ``
 }
  ${
    data.publicNotes
      ? `<tr>
  <td style="margin: 0; box-sizing: border-box; font-family: sans-serif; text-align: left; padding: 10px 0; font-size: 16px; font-weight: bold;">Public Notes</td>
  <td style="margin: 0; box-sizing: border-box; font-family: sans-serif; text-align: right; padding: 10px 0; font-size: 16px; font-weight: bold;">${
    data.publicNotes ? data.publicNotes : ''
  }</td>
  </tr>`
      : ``
  }

  </table>
  <p style="padding: 0; margin: 0; box-sizing: border-box; font-family: sans-serif; font-size: 16px; font-weight: 600; margin-bottom: 10px; text-align: center;">Thank you for your business. </p>
  <p style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; font-weight: 600; margin-bottom: 5px; margin-top: 30px;">Regards,</p>

 
   
  
  <p style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;"></p>
  
  `);

  my_window.document.write(`</body></html>`);
  my_window.print();
};

export default PrintInvoice;
