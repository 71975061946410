import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Topheader } from './../../components/Topheader';
import LoadingOverlay from 'react-loading-overlay';
import config from '../../constants/config';
import { DataGetApi } from '../../helpers/PostDataApi';
import { formatCurrency } from '../../helpers/commonFunction';
import { generateDataSource } from '../../helpers/transactionsTableSource';
import { NyTaxReports } from './../../pages/NyTaxReports';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import DataTable from 'react-data-table-component';
import DataTableExtensions from '../../components/TableExtensions/index';
import '../../components/TableExtensions/index.css';
import { setViewPreferences, fetchUserPreferences, markTransaction } from '../../actions/AdminUserAction';
import classes from './Customers.module.scss';
import { CloseableAlerts } from './../../components/Message/CloseableAlerts';
import Stack from '@mui/material/Stack';
import { CustomTransactionColumn } from '../Transactions/CustomTransactionColumn';
import { LinkTabs } from '../../components/Tabs';
import { Container } from '@material-ui/core';
class CustomerHistory extends Component {
  constructor(props) {
    super(props);
    const date = new Date();
    this.state = {
      sideShow: true,
      isLoading: true,
      reload: false,
      toDate: new Date(),
      fromDate: new Date(date.setDate(date.getDate() - 30)),
      data1: [],
      data: [],
      columns: [],
      misMatchError: [],
      columnsPreference: null,
      invoiceLable: 'Invoice',
    };
  }

  UNSAFE_componentWillMount() {
    //redirect to admin portal
    const locationDetails = this.props.LocationDetail?.currentLocation;
    if (locationDetails?.customTowingBuild) {
      this.props.history.push('/AdminPortal');
    }
  }

  UNSAFE_componentWillReceiveProps(nextPropsCustomer) {
    if (
      nextPropsCustomer.UserDetail &&
      nextPropsCustomer.UserDetail.userViewPreferences &&
      nextPropsCustomer.UserDetail.userViewPreferences.ViewColumns
    ) {
      const preferenceReceivedEvent = new CustomEvent('preferencesReceived', {
        detail: nextPropsCustomer.UserDetail?.userViewPreferences?.ViewColumns,
      });
      window.dispatchEvent(preferenceReceivedEvent);
      this.setState({ columnsPreference: nextPropsCustomer?.UserDetail?.userViewPreferences?.ViewColumns });
    }

    const currentlocation = nextPropsCustomer?.LocationDetail?.currentLocation;
    if (
      currentlocation &&
      currentlocation?.customFieldsEnabled &&
      currentlocation?.customFields &&
      currentlocation?.customFields.length > 0
    ) {
      const customFieldEnabled = currentlocation.customFields.find((customField) => customField?.enabled);
      if (customFieldEnabled) {
        this.setState({ invoiceLable: customFieldEnabled?.value });
      }
    }

    if (
      currentlocation &&
      currentlocation.customTransactionColumnEnabled &&
      currentlocation.customTransactionColumnFields.length > 0
    ) {
      const customTransactionColumn = currentlocation.customTransactionColumnFields.find(
        (customField) => customField?.enabled
      );
      if (customTransactionColumn) {
        this.setState({ transactionColumn: customTransactionColumn?.value });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('preferencesReceived', this.setColumnsPreferenceForCustomer);
  }

  componentDidMount() {
    window.addEventListener('preferencesReceived', this.setColumnsPreferenceForCustomer.bind(this));
    this.props.fetchUserPreferences();
    this.getdata();
  }

  setColumnsPreferenceForCustomer(event) {
    this.setState({ columnsPreference: event.detail });
  }
  handleDateChangeForCustomer = (date, key) => {
    if (date == 'Invalid Date' || date == null) {
      return false;
    } else {
      this.setState({ [key]: date }, () => {
        key === 'toDate'
          ? this.toDateFilterForCustomer(this.state.toDate)
          : this.fromDateFilterForCustomer(this.state.fromDate);
        this.getdata();
      });
    }
  };
  toDateFilterForCustomer(date) {
    this.setState({ toDate: date });
  }
  fromDateFilterForCustomer(date) {
    this.setState({ fromDate: date });
  }
  handleChangeIdForCustomer(id) {
    this.props.history.push('/Viewtransaction/' + id);
  }

  tableReload(status) {
    this.setState({ reload: status });
  }
  getPaymentStatus(val) {
    let Credit = 'credit';
    let value = val.toLowerCase();
    let ACH = 'ach';
    if (Credit.indexOf(value) !== -1) {
      return '101';
    } else if (ACH.indexOf(value) !== -1) {
      return '120';
    }
  }

  getdata() {
    return new Promise((resolve, reject) => {
      let url = config.BASEURL;
      let user = JSON.parse(localStorage.getItem('user'));
      let locationId = localStorage.getItem('locationArrIds');
      let token = user.token;
      let orderDirection = '';
      let orderBy = '';
      const {
        match: { params },
      } = this.props;
      let param = {
        locationIds: locationId,
        role: user.role,
        userId: user._id,
        customerId: params.id,
        companyId: user.company._id,
        per_page: '',
        defaultLocation: JSON.parse(locationId).length ? JSON.parse(locationId)[0] : null,
        page: '',
        toDate: this.state.toDate,
        fromDate: this.state.fromDate,
        searchAll: false,
        permissions: user.permissions,
        orderDirection: orderDirection,
        orderBy: orderBy,
      };
      DataGetApi(url + 'getCustomerTransections', param, token)
        .then((result) => {
          if (result.status === 200) {
            let modifiedData = [];
            modifiedData = result.data.response.map((item) => ({
              ...item,
              amount: item.AMOUNT ? formatCurrency(item.AMOUNT) : '$0.00',
              inventedBy:
                typeof item.createdBy != 'undefined'
                  ? item?.createdBy?.firstName + ' ' + item?.createdBy?.lastName
                  : '',
              location: item.locationId ? item.locationId.locationName : '',
              posted: item.posted ?? false,
            }));
            this.setState({ data1: modifiedData, isLoading: false });
            this.generateDataTableDataSource(modifiedData);
            resolve({
              data: modifiedData,
              totalCount: result.data.pagination.totalCount,
              page: result.data.pagination.page - 1,
            });
          } else {
            const msg =
              'Something went wrong while try to fetch transactions Please contact with techsupport@titaniumpayments.com or call 877-972-0700.';
            let misMatchError = [];
            misMatchError.push(msg);
            this.setState({ misMatchError });
            this.setState({ isLoading: false });
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({ isLoading: false });
        });
    });
  }
  generateDataTableDataSource(modifiedData) {
    const result = Object.values(modifiedData);
    let { data, columns } = generateDataSource(
      result,
      classes.Underline,
      this.handleChangeIdForCustomer.bind(this),
      this.state.invoiceLable,
      this.state.customTransactionColumnFields,
      this.state.transactionColumn
    );
    if (this.state.columnsPreference !== null) {
      Object.keys(this.state.columnsPreference).forEach((item) => {
        if (this.state.columnsPreference[item] === null) {
          const columnsPreference = this.state.columnsPreference;
          columnsPreference[item] = { omit: false };
          this.setState({ ...columnsPreference });
        }
      });
      columns = columns.map((col) => {
        if (!['Amount', 'Tax', 'Invoice', 'Location', 'Date', 'Notes'].includes(col.name)) {
          if (col.name == this.state.invoiceLable) {
            col.omit = this.state.columnsPreference['Invoice']?.omit ?? false;
            return col;
          }

          if (col.name == this.state.transactionColumn) {
            col.omit = this.state.columnsPreference['Posted']?.omit ?? false;
            return col;
          }

          if (col.name == result[0]?.svcFeeName) {
            col.omit = this.state.columnsPreference['SVC_Fee']?.omit ?? false;
            return col;
          } else {
            col.omit = this.state.columnsPreference[col.name.replaceAll(' ', '_')]?.omit ?? false;
            return col;
          }
        } else {
          col.omit = this.state.columnsPreference[col.name]?.omit ?? false;
          return col;
        }
      });
    }
    if (this.state.transactionColumn) {
      columns.unshift({
        name: this.state.transactionColumn,
        sortable: false,
        selector: (row) => row[this.state.transactionColumn],
        omit: this.state.columnsPreference !== null ? this.state.columnsPreference['Posted'].omit : false,
        cell: (row, _index, _column, _id) => {
          return <CustomTransactionColumn row={row} markTransaction={this.markTransaction.bind(this)} />;
        },
      });
    }
    this.setState({ data: data, columns: columns });
  }
  checkColumnForCustomer(name, checkValue) {
    const columns = this.state.columns.map((col) => {
      if (col.name == name) {
        col['omit'] = !checkValue;
        return col;
      }
      return col;
    });
    this.setState({ columns: columns });
    //the inclusion and exclusion of columns is concluded here.
  }
  saveUserSelectionForCustomer() {
    const columnsPreferences = {};
    this.state.columns.forEach((col) => {
      if (col.name == this.state.invoiceLable) {
        columnsPreferences['Invoice'] = {
          omit: col.omit ?? false,
        };
        return;
      }
      if (col.name == this.state.data1[0]?.svcFeeName) {
        columnsPreferences['SVC_Fee'] = {
          omit: col.omit ?? false,
        };
      }
      if (col.name == this.state.transactionColumn) {
        columnsPreferences['Posted'] = {
          omit: col.omit ?? false,
        };
      } else {
        columnsPreferences[col.name.replaceAll(' ', '_')] = {
          omit: col.omit ?? false,
        };
      }
    });
    this.props.setViewPreferences(columnsPreferences);
  }
  markTransaction(row, markValue) {
    const index = this.state.data.findIndex((rec) => rec['Transaction ID'] == row['Transaction ID']);
    if (index !== -1) {
      const testDataCustomer = [...this.state.data];
      const item = { ...testDataCustomer[index], Posted: !row.Posted };
      testDataCustomer[index] = item;
      this.setState({ data: [...testDataCustomer] });
      this.props.markTransaction({ txnid: row['Transaction ID'], mark: markValue });
    }
  }
  getMuiThemeForCustomer = () =>
    createMuiTheme({
      overrides: {
        MuiTypography: {
          root: {
            width: 'auto',
          },
        },
      },
    });
  removeCloseableAlertMessage = (index) => {
    let misMatchError = this.state.misMatchError;
    delete misMatchError[index];
    this.setState({ misMatchError });
  };
  render() {
    const { data, columns } = this.state;
    const {
      match: { params },
    } = this.props;
    const tableData = {
      data,
      columns,
      saveUserSelection: this.saveUserSelectionForCustomer.bind(this),
      checkColumn: this.checkColumnForCustomer.bind(this),
    };
    const paginationComponentOptions = {
      selectAllRowsItem: true,
      rangeSeparatorText: '-',
      rowsPerPageText: 'Rows Per Page',
      selectAllRowsItemText: 'All rows',
    };

    if (this.props.UserDetail.companyId._id === '5f4fdbe29bfe9c0e243a7598') {
      columns.push(
        {
          title: 'Base amount',
          field: 'Base amount',
          render: (rowData) =>
            rowData.invoices !== null && rowData.invoices.FIRST_PENALTY_AMOUNT > 0
              ? '$' + parseFloat(rowData.invoices.FIRST_PENALTY_AMOUNT).toFixed(2)
              : '',
        },
        {
          title: 'Penalty amount',
          field: 'Penalty amount',
          render: (rowData) =>
            rowData.invoices !== null && rowData.invoices.FIRST_PENALTY_AMOUNT > 0
              ? parseFloat(rowData.invoices.amount) - parseFloat(rowData.invoices.FIRST_PENALTY_AMOUNT) > 0
                ? `$ ${parseFloat(
                    parseFloat(rowData.invoices.amount) - parseFloat(rowData.invoices.FIRST_PENALTY_AMOUNT)
                  ).toFixed(2)}`
                : parseFloat(
                    parseFloat(rowData.invoices.amount) - parseFloat(rowData.invoices.FIRST_PENALTY_AMOUNT)
                  ).toFixed(2)
              : '',
        },
        { title: 'Amount charged', field: 'amount' }
      );
    }

    const customStyles = {
      rows: {
        style: {
          borderBottomColor: 'initial !important',
          borderBottomWidth: '0 !important',
          borderBottomStyle: 'initial !important',
          ':hover div': {
            backgroundColor: '#EEEEEE',
          },
          div: {
            fontSize: '14px',
          },
        },
      },
      headCells: {
        style: {
          borderBottomColor: 'rgba(0,0,0,.12)',
          borderBottomWidth: '2px',
          borderBottomStyle: 'solid',
          div: {
            whiteSpace: 'break-spaces',
            overflow: 'initial',
            fontSize: '14px',
          },
        },
      },
      cells: {
        style: {
          borderBottomColor: 'rgba(0,0,0,.12)',
          borderBottomWidth: '1px',
          borderBottomStyle: 'solid',
          'div.parent:hover': {
            background: 'black',
          },
        },
      },
    };

    return (
      <div className="rightPanel">
        <Topheader />
        <div className="contentPart">
          <LinkTabs id={params.id} />
          <Stack sx={{ width: '100%' }} spacing={2}>
            {this.state.misMatchError.map((e, i) => (
              <CloseableAlerts
                key={i}
                index={i}
                message={e}
                severity="error"
                closer={this.removeCloseableAlertMessage}
              />
            ))}
          </Stack>
          <LoadingOverlay active={this.state.isLoading} spinner text="Loading your content...">
            <Container component="main" maxWidth="xl">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  variant="inline"
                  margin="normal"
                  format="MM/dd/yyyy"
                  autoOk
                  value={this.state.fromDate}
                  label={'From Date'}
                  id="date-picker-inline1"
                  onChange={(date) => {
                    this.handleDateChangeForCustomer(date, 'fromDate');
                  }}
                  datefilter={true}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  variant="inline"
                  value={this.state.toDate}
                  format="MM/dd/yyyy"
                  label={'To Date'}
                  margin="normal"
                  autoOk
                  id="date-picker-inline1"
                  onChange={(date) => {
                    this.handleDateChangeForCustomer(date, 'toDate');
                  }}
                  datefilter={true}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
              <MuiThemeProvider theme={this.getMuiThemeForCustomer()}>
                <DataTableExtensions {...tableData}>
                  <DataTable
                    columns={columns}
                    defaultSortAsc={false}
                    data={data}
                    defaultSortField="id"
                    paginationComponentOptions={paginationComponentOptions}
                    pagination
                    highlightOnHover
                    noHeader
                    view={true}
                    print={true}
                    customStyles={customStyles}
                  />
                </DataTableExtensions>
              </MuiThemeProvider>
            </Container>
          </LoadingOverlay>

          {this.props.UserDetail.companyId._id === '5f4fdbe29bfe9c0e243a7598' ? <NyTaxReports /> : ''}
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    info: state.PaymentReducer,
    UserDetail: state.AdminUserReducer,
    LocationDetail: state.LocationReducer,
  };
}
CustomerHistory.propTypes = {
  UserDetail: PropTypes.any,
  LocationDetail: PropTypes.object,
  match: PropTypes.any,
  history: PropTypes.any,
  fetchUserPreferences: PropTypes.func,
  setViewPreferences: PropTypes.func,
  markTransaction: PropTypes.func,
};

export default connect(mapStateToProps, { setViewPreferences, fetchUserPreferences, markTransaction })(
  withTranslation()(CustomerHistory)
);
